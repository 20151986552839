.results_root {
  width: 100%;
  height: 93vh;
  display: grid;
  grid-template-columns: 240px auto 360px;
  grid-template-rows: 36px auto;
  grid-template-areas:
    "sidemenu stuffSelector calendar"
    "sidemenu content calendar";
  column-gap: 1rem;
  row-gap: 0.3rem;
}
.results_side_mobile {
  grid-area: sidemenumobile;
  display: none;
}
.results_side_menu {
  grid-area: sidemenu;
}
.results_stuff_selector {
  grid-area: stuffSelector;
  z-index: 1;
  position: fixed;
  width: calc(100% - 640px);
  height: 36px;
  margin-left: 260px;
}
.results_content {
  grid-area: content;
}
.results_calendar_side {
  grid-area: calendar;
}
@media all and (max-width: 1280px) {
  .results_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 52px auto auto;
    grid-template-areas: "sidemenumobile" "calendar" "content";
    row-gap: 0;
    column-gap: 0;
  }
  .results_side_mobile {
    display: block;
  }
  .results_side_menu {
    display: none;
  }
  .results_stuff_selector {
    display: none;
  }
}
@media all and (max-width: 1366px) {
  .blanker {
    height: 8vh !important;
  }
}

.content_results_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px minmax(auto, calc(100vh - 15vh));
  grid-template-areas: "resultMinih" "resultContentLisst";
  row-gap: 0.1rem;
}
.content_results_miniheader {
  grid-area: resultMinih;
}
.content_results_list {
  grid-area: resultContentLisst;
}
@media all and (max-width: 1440px) {
  .content_results_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px auto;
    grid-template-areas: "miniHeader" "contentList";
    row-gap: 0;
  }

  .content_results_miniheader {
    grid-area: miniHeader;
  }
  .content_results_list {
    grid-area: contentList;
  }
}
