.profile-root {
  width: 100%;
  height: 100%;
  display: flex;
}
.profile-side-menu {
  height: 33%;
  width: 210px;
  z-index: 12;
}
.profile-content {
  height: 100%;
  width: 80%;
  z-index: 12;
}
.profile-side-root {
  width: 100%;
  height: 100%;
  margin-left: 3%;
}
.nav-sider-wrapper {
  background-color: #282828;
  border-radius: 4px;
}
.nav-sider-tree {
  display: block;
  padding: 0;
}
.nav-sider-tree-item {
  display: flex;
  transition: all 0.2s ease;
  cursor: pointer;
  min-height: 36px;
  line-height: 33px;
  font-size: 13px;
  border-bottom: 1px solid black;
  font-weight: 500;
  color: #b4b4b4;
  text-transform: uppercase;
  position: relative;
}
.nav-sider-tree-item a {
  all: unset;
}
.nav-sider-tree-item:hover {
  background-color: #666262;
}
.nav-sider-tree-item-icon {
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 6px;
  color: #b4b4b4;
  vertical-align: middle;
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.player-tickets-root {
  width: 100%;
  height: 100%;
}
.player-tickets-dateSelector-btns {
  width: 100%;
  height: 3%;
  background-color: #282828;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 6px;
  display: flex;
}
.player-ticket-date-selector-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-right: 1px solid #333;
  font-size: 14px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
}
.player-ticket-date-selector-btn:hover {
  background-color: #666262;
}
.is_date_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.nav-sider-tree-item-label a {
  all: unset;
}
.player-tickets-header {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 6px;
}
.player-tickets-label-icon {
  color: #b4b4b4;
  vertical-align: middle;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.player-tickets-label {
  color: #b4b4b4;
  font-size: 21px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  margin-left: 9px;
  line-height: 30px;
}
.player-tickets-ongoing {
  width: 100%;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 6px;
  background-color: #282828;
  border-radius: 4px;
  min-height: 33%;
  max-height: 40%;
  overflow-y: auto;
}
.player-t-ongoing-header {
  width: 100%;
  height: 6%;
  margin-top: 0.6%;
  display: flex;
}
.player-t-o_h-icon {
  color: #b4b4b4;
  margin-top: 3px;
  margin-right: 9px;
  margin-left: 9px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.player-t-o_h-label {
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}
.player-t-ongoing-subheader {
  width: 100%;
  height: 5%;
  margin-top: 0.6%;
  display: flex;
  background-color: #282828;
  color: #b4b4b4;
  font-size: 12px;
  text-transform: uppercase;
}
.player-t-ongoing-content {
  max-height: 33vh;
}
.__lb1 {
  width: 9%;
  text-align: left;
  margin-left: 3px;
}
.__lb2 {
  width: 10%;
  text-align: left;
  margin-left: 3px;
}
.__lb3 {
  width: 51%;
  text-align: left;
  margin-left: 3px;
}
.__lb4 {
  width: 10%;
  text-align: left;
  margin-left: 3px;
}
.__lb5 {
  width: 9%;
  text-align: left;
  margin-left: 3px;
}
.player-tickets-finished {
  width: 100%;
  margin-top: 27px;
  margin-left: 60px;
  margin-right: 6px;
  background-color: #282828;
  border-radius: 4px;
  min-height: 33%;
  max-height: 40%;
  overflow-y: auto;
}
.profile_history-row {
  display: flex;
  height: 150px;
}
.profile_history-status {
  display: flex;
}
.profile_history__row {
  width: 100%;
  height: 30px;
  margin-top: 0.6%;
  display: flex;
  background-color: #282828;
  color: #b4b4b4;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}
.profile_history-status-icon-x {
  color: #ea060a;
  margin-left: 3px;
  margin-right: 3px;
  width: 18px !important;
  height: 18px !important;
  margin-top: 6px;
}
.profile_history-status-icon-v {
  color: green;
  margin-left: 3px;
  margin-right: 3px;
  width: 18px !important;
  height: 18px !important;
  margin-top: 6px;
}
.profile_history-status-icon-w {
  color: #b4b4b4;
  margin-left: 3px;
  margin-right: 3px;
  width: 18px !important;
  height: 18px !important;
  margin-top: 6px;
}
.profile_history-status-date {
  font-size: 10px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 9px;
}
.profile_history-tip {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile-history-cod-bilet {
  font-size: 14px;
  color: #b4b4b4;
  font-weight: 600;
  margin-top: 3px;
}
.profile-history-cota {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile-history-cota {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile_history-mize {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile_history-mize span {
  font-size: 10px;
  color: #b4b4b4;
}
.profile_history-plata {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile_history-plata span {
  font-size: 10px;
  color: #b4b4b4;
}

@media all and (max-width: 1280px) {
  .profile-root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: "profile_side" "profile_content";
    row-gap: 0.4rem;
  }
  .profile-side-menu {
    grid-area: profile_side;
    width: 100%;
  }
  .profile-content {
    grid-area: profile_content;
    width: 100%;
  }
  .nav-sider-tree {
    display: flex !important;
    flex-wrap: wrap;
  }
  .nav-sider-tree-item {
    width: calc(50% - 1px);
    border: none;
    border-bottom: 1px solid black;
  }
  .nav-sider-tree-item:last-of-type {
    border: none;
  }
  .profile-side-root {
    margin: 0;
  }
  .mobile_bordered {
    border-right: 1px solid black;
  }
  .player-tickets-dateSelector-btns {
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
  .player-tickets-header {
    width: 90%;
    margin: 0;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .player-tickets-ongoing {
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
  .player-t-ongoing-subheader {
    margin-top: 7px;
    border-bottom: 1px solid #b4b4b4;
  }
  .__lb1 {
    width: 50px;
  }
  .profile_history-status-date {
    display: none !important;
  }
  .__lb2 {
    display: none;
  }
  .__lb3 {
    width: 100px !important;
  }
  .__lb4 {
    width: 90px !important;
  }
  .__lb5 {
    display: none;
  }
  .player-t-ongoing-content > div {
    border-bottom: 1px solid black;
  }
  .player-tickets-finished {
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 3%;
  }
}
