.navigation_root {
  display: grid;
  grid-template-columns: 240px 62% auto auto auto; /*MORE btn IS NOT DISPLAYED*/
  grid-template-rows: 3vh 3vh;
  grid-template-areas:
    "logo menu date login register"
    "logo menu time login register";
}
.nav_more {
  grid-area: more;
  display: none;
}
.nav_logo {
  grid-area: logo;
}
.nav_menu {
  grid-area: menu;
  display: flex;
}
.nav_date {
  grid-area: date;
}
.nav_time {
  grid-area: time;
}
.nav_login {
  grid-area: login;
}
.nav_register {
  grid-area: register;
}
.user_badge_wrap {
  grid-area: login;
}

@media all and (max-width: 1440px) {
  .navigation_root {
    display: grid;
    grid-template-columns: 240px auto 10% 10%;
    grid-template-rows: 6vh;
    grid-template-areas: "logo menu login register";
  }
  .nav_date {
    display: none;
  }
  .nav_time {
    display: none;
  }
}

@media all and (max-width: 1280px) {
  .navigation_root {
    display: grid;
    grid-template-columns: 40px auto 119px;
    grid-template-rows: 6vh;
    grid-template-areas: "more logo login";
  }
  .nav_more {
    display: block;
  }
  .nav_menu {
    display: none;
  }
  .nav_register {
    display: none;
  }
  .nav_logo img {
    height: 55px !important;
  }
}

@media screen and (max-width: 1366px) {
  .nav_logo img {
    height: 45px !important;
    width: 150px !important;
    margin-left: 12px;
    margin-top: 2px;
  }
}

@media all and (max-width: 786px) {
  .navigation_root {
    grid-template-columns: 40px auto 90px;
    grid-template-rows: 45px;
  }
  .nav_more {
    padding: 6px !important;
  }
  .nav_logo img {
    height: 40px !important;
    width: 120px !important;
  }
  .nav_login {
    line-height: 1.5 !important;
  }
}
