@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layout_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6vh 93vh;
  grid-template-areas: "navigation" "children";
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}
.layout_navheader {
  width: 100%;
  height: 6vh;
  grid-area: navigation;
  background-color: #000000;
  color: #fffdff;
  border-bottom: 1px solid #f47f32;
  position: fixed;
  z-index: 119321;
}
.layout_children {
  grid-area: children;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.err_msg_btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.err_msg_btns > span:nth-child(1) {
  padding: 0.6rem;
  min-width: 39px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  cursor: pointer;
}
.err_msg_btns > span:nth-child(2) {
  min-width: 39px;
  padding: 0.6rem;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  cursor: pointer;
}
@media all and (max-width: 1440px) {
  .layout_root {
    grid-row-gap: 0rem;
    row-gap: 0rem;
  }
}
@media screen and (max-width: 1366px) {
  .layout_root {
    grid-template-rows: 8vh 91vh;
  }
}

.blanker {
  width: 100%;
  height: 13.45vh;
  background-color: #1a1a1a;
  position: fixed;
  z-index: 0;
}

.navigation_root {
  display: grid;
  grid-template-columns: 240px 62% auto auto auto; /*MORE btn IS NOT DISPLAYED*/
  grid-template-rows: 3vh 3vh;
  grid-template-areas:
    "logo menu date login register"
    "logo menu time login register";
}
.nav_more {
  grid-area: more;
  display: none;
}
.nav_logo {
  grid-area: logo;
}
.nav_menu {
  grid-area: menu;
  display: flex;
}
.nav_date {
  grid-area: date;
}
.nav_time {
  grid-area: time;
}
.nav_login {
  grid-area: login;
}
.nav_register {
  grid-area: register;
}
.user_badge_wrap {
  grid-area: login;
}

@media all and (max-width: 1440px) {
  .navigation_root {
    display: grid;
    grid-template-columns: 240px auto 10% 10%;
    grid-template-rows: 6vh;
    grid-template-areas: "logo menu login register";
  }
  .nav_date {
    display: none;
  }
  .nav_time {
    display: none;
  }
}

@media all and (max-width: 1280px) {
  .navigation_root {
    display: grid;
    grid-template-columns: 40px auto 119px;
    grid-template-rows: 6vh;
    grid-template-areas: "more logo login";
  }
  .nav_more {
    display: block;
  }
  .nav_menu {
    display: none;
  }
  .nav_register {
    display: none;
  }
  .nav_logo img {
    height: 55px !important;
  }
}

@media screen and (max-width: 1366px) {
  .nav_logo img {
    height: 45px !important;
    width: 150px !important;
    margin-left: 12px;
    margin-top: 2px;
  }
}

@media all and (max-width: 786px) {
  .navigation_root {
    grid-template-columns: 40px auto 90px;
    grid-template-rows: 45px;
  }
  .nav_more {
    padding: 6px !important;
  }
  .nav_logo img {
    height: 40px !important;
    width: 120px !important;
  }
  .nav_login {
    line-height: 1.5 !important;
  }
}

.nav_more {
  cursor: pointer;
  align-items: center;
  color: #fffdff;
  padding: 15px 0px 12px 6px;
}
.nav_more_icon {
  text-align: center;
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.nav_logo {
  margin-left: 3px;
  font-weight: 500;
  cursor: pointer;
}
.nav_logo img {
  height: 60px;
}
.nav_menu_item {
  color: #fffdff;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 12px 0px 12px;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
}
.is_active::after {
  content: "";
  position: absolute;
  left: 2%;
  right: 2%;
  bottom: 0;
  border: 2px solid #f47f32;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.nav_menu_item a {
  all: unset;
}
.is_active {
  color: #f47f32;
}
.nav_date {
  padding: 9px 3px 0px 0px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}
.nav_time {
  padding: 0px 3px 6px 0px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}
.nav_login {
  width: 80%;
  padding: 1 6 1 6;
  margin-left: 10%;
  line-height: 3;
  text-align: center;
  height: 3vh;
  margin-top: 1.2vh;
  background-color: #f47f32;
  border-radius: 3px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #f47f32;
  font-weight: 500;
}
.nav_login:hover {
  background-color: #ca6e31;
  border: 1px solid #9b4b16;
}
.nav_register a {
  all: unset;
}
.nav_register {
  width: 80%;
  padding: 1 6 1 6;
  line-height: 3;
  text-align: center;
  height: 3vh;
  margin-top: 1.2vh;
  border-radius: 3px;
  border: 1px solid white;
  color: white;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}
.nav_register:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/*---MODAL LOGIN---*/

.modal_login {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  overflow: hidden;
}
.modal_login_container {
  width: 360px;
  max-width: 360px;
  background-color: rgb(187, 187, 187);
  border-radius: 4px;
  padding: 32px 40px;
  margin: 120px auto 60px;
  position: relative;
}
.modal_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background-color: rgb(187, 187, 187);
}
.modal_title {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.modal_header_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #999;
  align-self: flex-start;
  margin-left: 12px;
}
.modal-forms {
  padding: 8px 0;
}
.form-group {
  box-sizing: inherit;
}
.form-field-user {
  margin-bottom: 12px;
  min-height: 76px;
  position: relative;
}
.input-label {
  display: flex;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  color: #333;
  margin-bottom: 8px;
}
.input-field-box {
  box-sizing: inherit;
}
.input-field {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 100%;
  color: #333;
  font-size: 12px;
  margin-bottom: 4px;
  height: 32px;
  padding: 0 8px;
  outline: 0;
}
.pass-input {
  justify-content: space-between;
}
.forgot-pass {
  color: #333;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  margin-left: 67%;
}
.modal-login-button {
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #f47f32;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  padding: 0 12px;
  height: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  transition: all 0.1s ease-in;
}
.modal_buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 8px;
  color: #333;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
}
.goto_link {
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: underline !important;
}

.user_badge_wrap {
  all: unset;
  display: block;
  text-align: center;
  padding: 6px;
  margin-top: 3px;
  cursor: pointer;
}
.user_badge_user {
  color: #4a4a4a;
  font-size: 12px;
}
.user_badge_balance {
  color: #f47f32;
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
}
.more_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 21vh; */
  margin-top: 6vh;
  background-color: rgba(247, 117, 30, 0.99);
  overflow: hidden;
  position: fixed;
  border-radius: 6px;
}
.more_mobile_wrapper {
  border-radius: 4px;
  display: block;
  padding: 12px;
}
.more_mobile_wrapper a {
  all: unset;
  color: black;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 12px 0px 12px;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 12px;
}
.is_mob_active {
  background-color: #f3731e;
}
.bottom_brd {
  border-bottom: 1px solid black;
  width: 100%;
}
.login_failed_err_msg {
  text-align: center;
  color: red;
  font-size: 12px;
  margin-bottom: 6px;
  font-weight: 500;
}
@media all and (max-width: 786px) {
  .modal_login_container {
    width: 270px;
    max-width: 270px;
  }
}

.date_selector_root {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
  z-index: 12150;
  background-color: #1a1a1a;
}
.date_selector_button {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
  background-color: #1a1a1a;
}
.date_selector_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.date_selector_button:hover {
  background-color: #666262;
}
.date_selector_button a {
  all: unset;
}

@media all and (max-width: 1280px) {
  .date_selector_root {
    display: block;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    height: 100%;
    z-index: 50;
  }
  .date_selector_button {
    background-color: #282828;
    height: 25px;
    margin-top: 6px;
  }
}

.loto_root {
  width: 100%;
  height: 93vh;
  display: grid;
  grid-template-columns: 240px auto 360px;
  grid-template-rows: 36px auto;
  grid-template-areas:
    "side dateSelector ticket"
    "side content ticket";
  grid-column-gap: 1rem;
  column-gap: 1rem;
}
.side_menu_mobile {
  grid-area: sideMobile;
  display: none;
}
.side_menu {
  grid-area: side;
}
.date_selector {
  grid-area: dateSelector;
  position: fixed;
  width: calc(100% - 650px);
  height: 36px;
  margin-left: 265px;
  margin-right: 360px;
}
.hour_selector {
  grid-area: hourSelector;
}
.content {
  grid-area: content;
}
.ticket {
  grid-area: ticket;
}
.ticket_mobile {
  grid-area: ticketMobile;
  display: none;
}

@media screen and (max-width: 1366px) {
  .loto_root {
    height: 91vh;
    grid-template-columns: 220px auto 330px;
    grid-template-rows: 36px auto;
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }
  .date_selector {
    width: calc(100% - 588px) !important;
    margin-left: 238px;
  }
}

@media all and (max-width: 1280px) {
  .loto_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 42px auto 50px;
    grid-template-areas: "sideMobile" "content" "ticketMobile";
  }
  .side_menu {
    display: none;
  }
  .ticket {
    display: none;
  }
  .date_selector {
    display: none;
  }
  .hour_selector {
    display: none;
  }
  .ticket_mobile {
    display: block;
  }
  .side_menu_mobile {
    display: block;
  }
}
@media all and (max-width: 786px) {
  .loto_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "sideMobile" "content" "ticketMobile";
  }
  .side_menu {
    display: none;
  }
  .ticket {
    display: none;
  }
  .date_selector {
    display: none;
  }
  .hour_selector {
    display: none;
  }
  .ticket_mobile {
    display: block;
  }
  .side_menu_mobile {
    display: block;
  }
}

/*Side-menu grid*/
.side_menu_root {
  display: grid;
  grid-template-columns: 240px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "topidx"
    "defaultMenu";
  margin-left: 0.6rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  position: fixed;
}
.top_idx_lotteries {
  grid-area: topidx;
}
.tabs_menus {
  grid-area: defaultMenu;
}

@media screen and (max-width: 1366px) {
  .side_menu_root {
    grid-template-columns: 220px;
  }
}

/*Side-menu @-cu-@ generic-side-menu*/
.generic_sidemenu_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto;
  grid-template-areas: "tabs" "genericMenu";
  grid-row-gap: 0.3rem;
  row-gap: 0.3rem;
}
.generic_tabs {
  grid-area: tabs;
}
.generic_list {
  grid-area: genericMenu;
}

/*Content Area*/
.content_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto;
  grid-template-areas: "miniHeader" "contentList";
  grid-row-gap: 0.1rem;
  row-gap: 0.1rem;
}
.content_mini_header {
  grid-area: miniHeader;
}
.content_list {
  grid-area: contentList;
}

@media screen and (max-width: 1366px) {
  .content_mini_header {
    width: calc(100% - 591px) !important;
    margin: 0 !important;
    margin-left: 4px !important;
    margin-top: 4px !important;
  }
}

@media all and (max-width: 1440px) {
  .content_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px 80vh;
    grid-template-areas: "miniHeader" "contentList";
    grid-row-gap: 0;
    row-gap: 0;
  }

  .content_miniheader_odds {
    display: none;
  }
  .content_miniheader_odd {
    display: none;
  }
}

/*CONTENT SINGLE ROW*/
.content_single_row_root {
  display: grid;
  grid-template-columns: 7% 3% 16% auto 40.5%;
  grid-template-rows: auto minmax(0px, auto);
  grid-template-areas:
    "data shopCode nume system cote"
    "playtable playtable playtable playtable playtable";
}
.content_single_row_date {
  grid-area: data;
}
.content_single_row_shopCode {
  grid-area: shopCode;
}
.content_single_row_name {
  grid-area: nume;
}
.content_single_row_system {
  grid-area: system;
}
.content_single_row_odds {
  grid-area: cote;
}
.playtable {
  grid-area: playtable;
  min-height: 540px;
}
@media all and (max-width: 1440px) {
  .content_single_row_root {
    display: grid;
    grid-template-columns: 150px 80px auto 150px;
    grid-template-rows: auto minmax(0px, auto);
    grid-template-areas: "data shopCode nume system" "playtable playtable playtable playtable";
  }
  .content_single_row_odds {
    display: none !important;
  }
}
@media all and (max-width: 786px) {
  .content_single_row_root {
    display: grid;
    grid-template-columns: 90px 30px auto 50px;
    grid-template-rows: auto minmax(0px, auto);
    grid-template-areas: "data shopCode nume system" "playtable playtable playtable playtable";
  }
  .content_single_row_odds {
    display: none !important;
  }
}

/*------PLAY TABLE-------*/
.playtable_root {
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-rows: 30px auto;
  grid-template-areas:
    "pt_header pt_header"
    "left_pt right_pt";
}
.playtable_header {
  grid-area: pt_header;
}
.playtable_left_specials {
  grid-area: left_pt;
}
.playtable_header_mobile {
  grid-area: pt_header_specials;
}
.playtable_right_game {
  margin-left: 6px;
  padding: 16px;
  grid-area: right_pt;
}
.playtable_header_mobile {
  display: none;
}
.playtable_mobile_special_buttons_res {
  display: none;
  grid-area: pt_header_special_div;
}

.playtable_specials_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 130px 240px auto;
  grid-template-areas:
    "generator"
    "combinations"
    "addFav";
}
.playtable_specials_num_gen {
  grid-area: generator;
}
.playtable_specials_combinations {
  grid-area: combinations;
}
.playtable_specials_favs {
  grid-area: addFav;
}

@media all and (max-width: 1280px) {
  .playtable_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20px 80px auto auto;
    grid-template-areas: "pt_header" "pt_header_specials" "pt_header_special_div" "right_pt";
  }
  .playtable_left_specials {
    display: none;
  }
  .play_table_header_lbl {
    display: none;
  }
  .play_table_time_left {
    display: none;
  }
  .play_table_max_nums {
    display: none;
  }
  .play_table_up_arrow {
    width: 100% !important;
  }
  .play_table_up_arrow {
    width: auto;
    text-align: left;
  }
  .playtable_header_mobile {
    display: flex !important;
    margin-top: 12px;
  }
  .playtable_header_mobile_random {
    width: 33%;
    line-height: 5;
    text-align: center;
    color: white;
    cursor: pointer;
  }
  .playtable_mobile_special_buttons_res {
    display: block;
  }

  .playtable_num_generator_root {
    width: 100% !important;
    border: none !important;
  }
  .playtable_num_gen_btns_header {
    margin: 0 !important;
    text-align: center;
  }
  .playtable_num_gen_btns_wheel {
    width: 45% !important;
    margin-left: 35% !important;
    margin-right: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .playtable_com_header {
    margin-left: 10px !important;
    margin-bottom: 0 !important;
  }
}

/*--------TICKET---------*/
.ticket_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto auto auto auto auto;
  grid-template-areas:
    "verification"
    "ticketHead"
    "ticketNumbers"
    "ticketCombinations"
    "ticketStake"
    "ticketButton";
  margin-right: 2%;
  width: 350px;
  height: auto;
  position: fixed;
}
.ticket_verify_ticket {
  grid-area: verification;
}
.ticket_header {
  margin-top: 21px;
  grid-area: ticketHead;
  background-color: #282828;
}
.ticket_nums {
  grid-area: ticketNumbers;
  background-color: #282828;
}
.ticket_combinations {
  grid-area: ticketCombinations;
  background-color: #282828;
}
.ticket_stake {
  grid-area: ticketStake;
  background-color: #282828;
}
.ticket_button {
  grid-area: ticketButton;
  background-color: #282828;
}

@media screen and (max-width: 1366px) {
  .ticket_root {
    width: 320px;
    margin: 0 !important;
  }
  .ticket_verify_root {
    width: 320px !important;
    padding: 3px !important;
  }
  .ticket_verify_intup {
    padding: 1px !important;
    height: 20px !important;
  }
  .ticket_verify_button {
    height: 23px !important;
    width: 23px !important;
  }
}

@media all and (max-width: 786px) {
  .top_idx_lotteries {
    max-height: 30vh;
    overflow: auto;
  }
  .generic_list {
    max-height: 45vh !important;
    overflow: auto;
  }
}

/*TOP IDX*/
.top_idx_lotteries {
  background-color: #282828;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid black;
}
.top_idx_single_element {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid black;
  text-align: center;
  cursor: pointer;
  display: flex;
}
.top_idx_single_element:hover {
  background-color: #666262;
}
.top_idx_logo {
  width: 30px;
  display: flex;
  align-items: center;
}
.top_idx_span {
  width: calc(100% - 110px);
  margin-left: 6px;
  color: whitesmoke;
  font-size: 12px;
  font-weight: 500;
  line-height: 3.3;
  text-align: left;
}
.top_idx_logo_i {
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 45%;
  border: 1px solid black;
}
.top_idx_balls_logo {
  width: 46px;
  display: flex;
  align-items: center;
}
.top_idx_guess {
  background-color: rgb(207, 207, 207);
  border: 1px solid black;
  color: black;
  margin-right: -2px;
  z-index: 1;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.top_idx_total {
  background-color: #7a7a7a;
  color: black;
  border: 1px solid black;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.top_idx_camera {
  width: 30px;
}
.top_idx_camera_i {
  color: white;
  padding-top: 9px;
  padding-left: 3px;
}
/*endOf TOP IDX --> GENERIC SIDE MENU*/
/*----------SIDE TABS---------*/
.generic_side_tabs {
  width: 100%;
  display: flex;
  background-color: #282828;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 36px;
  display: flex;
  z-index: 50;
  position: relative;
  text-transform: uppercase;
}
.generic_side_tab {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-right: 1px solid black;
  font-size: 12px;
  color: #f47f32;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}
.generic_side_tab:hover {
  background-color: #666262;
}
.generic_side_tab a {
  all: unset;
}
.generic_side_tab_active {
  border-bottom: 2px solid #f47f32;
  background-color: #282828;
}
/*----------GENERIC LIST + SUBITEM---------*/
.generic_list {
  background-color: #282828;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}
.generic_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.generic_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.generic_list::-webkit-scrollbar-thumb {
  background-color: #f47f32;
}

.all_button_country_item {
  text-align: left;
  border-bottom: 1px solid black;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: whitesmoke;
  width: 100%;
}
.all_button_span {
  text-transform: uppercase;
  margin-left: 18px;
  width: 66%;
}
.all_button_country_item:hover {
  background-color: #666262;
}
.all_button_icon {
  border-radius: 50%;
  margin-top: 2.4%;
  margin-left: 1%;
}
.all_button_country_item_active {
  background-color: #666262;
  border-left: 2px solid #f47f32;
}
.all_button_camera {
  padding-top: 3px;
}
.generic_country_subitem {
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid black;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  cursor: pointer;
  color: #b4b4b4;
  text-transform: uppercase;
  width: 100%;
  display: flex;
}
.generic_subitem_spaning {
  width: 80%;
  margin-left: 15px;
}
.subitem_active {
  background-color: rgba(234, 6, 10, 0.05);
}
.generic_country_subitem:hover {
  background-color: darkgray;
  color: darkslategrey;
}
.generic_subitem_camera {
  padding-top: 3px;
}
/* .generic_country_subitem span {
  margin-left: 15px;
} */
/*--------SideMenuForSystems----*/
.generic_list_root_sys {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 12px;
  z-index: 10;
}
.system_flexin_half {
  width: 50%;
}
.generic_list_all_button_sys {
  display: inline-flex;
  flex: 0 0 100%;
  margin-bottom: 12px;
  align-items: center;
}
.generic_system_item_label {
  font-size: 14px;
  color: #b4b4b4;
  margin-left: 3px;
}
.generic_system_item_span {
  min-height: 16px;
  vertical-align: middle;
  color: #b4b4b4;
  text-align: left;
  font-weight: bold;
  line-height: 2;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: #b4b4b4;
  text-transform: uppercase;
}

.generic_list_rest_items_sys {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px 0px 0px;
  z-index: 10;
}
.generic_system_item_root {
  display: inline-flex;
  flex: 0 0 50%;
  padding-right: 21px;
  margin-bottom: 12px;
  align-items: center;
}
/*  width: calc(100% - 650px);
  height: 36px;
  margin-left: 265px;
  margin-right: 360px;
}*/

/*---------CONTENT <> mini header---------*/
.content_mini_header {
  width: calc(100% - 650px);
  display: flex;
  align-items: center;
  background-color: #363636;
  font-size: 10px;
  color: #b4b4b4;
  position: fixed;
  z-index: 40;
  margin-top: 6px;
  height: 30px;
  margin-left: 8px;
}
.content_miniheader_label {
  width: 60%;
  margin-left: 12px;
}
.content_miniheader_odds {
  width: 41%;
  display: flex;
}
.content_miniheader_odd {
  width: 12.5%;
  text-align: center;
}
/*---------CONTENT <> list--------*/
/* .infinite-scroll-component::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.infinite-scroll-component::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: #f47f32;
}
*/
/* .content_root {
  z-index: 1;
} */
.content_list {
  width: 98.9%;
  margin-left: 0.5%;
}
/*---------CONTENT <> SINGLE ROW*/
.content_single_row_root {
  padding: 6px;
  color: #333;
  font-size: 12px;
  border-bottom: 1px solid black;
  background-color: #282828;
  cursor: pointer;
}
.content_single_row_date {
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  line-height: 2;
}
.content_single_row_shopCode {
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  line-height: 2;
}
.content_single_row_name {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
  text-align: left;
  color: whitesmoke;
}
.content_single_row_system {
  display: flex;
}
.content_row_system_draw {
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
}
.content_row_system_outOf {
  background-color: #7a7a7a;
  color: black;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
  border: 1px solid transparent;
}
.content_single_row_odds {
  display: flex;
}
.content_single_row_odd {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  width: 12.5%;
  color: #b4b4b4;
}

/*---PLAY TABLE------*/
.play_table_header_root {
  background: #3d3d3d;
  display: flex;
  width: 100%;
  height: 30px;
}
.play_table_header_lbl {
  width: 240px;
  color: #b4b4b4;
  font-size: 10px;
  text-transform: uppercase;
  align-items: center;
  background-color: #3d3d3d;
  line-height: 3;
  margin-left: 6px;
}
.play_table_time_left {
  width: 50px;
  font-size: 10px;
  line-height: 16px;
  background: #3d3d3d;
  color: #b4b4b4;
  height: 28px;
  line-height: 3;
  margin-left: 6px;
}
.play_table_max_nums {
  width: auto;
  color: #b4b4b4;
  font-size: 10px;
  line-height: 3;
  text-align: right;
}
.play_table_up_arrow {
  width: 66%;
  color: #f47f32;
  font-size: 12px;
  line-height: 2.5;
  text-align: center;
}
.playtable_specials_root {
  width: 240px;
  border-right: 1px solid black;
  margin-left: 6px;
}
.playtable_num_generator_root {
  width: 240px;
  border-right: 1px solid black;
}
.playtable_num_gen_buttons {
  background-color: #282828;
  padding: 12px;
  box-sizing: inherit;
}
.playtable_num_gen_btns_header {
  color: #b4b4b4;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-align: center;
}
.playtable_num_gen_btns_wheel {
  align-items: center;
  display: flex;
  width: 112px;
  margin-left: 25%;
  margin-bottom: 12px;
}
.playtable_num_gen_minus {
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  transition: all 0.1s ease-in;
  background: #282828;
  border: 1px solid black;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}
.playtable_num_gen_value {
  color: #b4b4b4;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  width: 48px;
}
.playtable_num_gen_plus {
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  transition: all 0.1s ease-in;
  background: #282828;
  border: 1px solid black;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}
.playtable_num_gen_action {
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.1s ease-in;
  color: black;
  font-weight: 600;
  background-color: #f47f32;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 6px;
}

.playtable_com_header {
  display: flex;
  height: 28px;
  color: #b4b4b4;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 0 12px;
  align-items: center;
  background-color: #282828;
  margin-bottom: 8px;
}
.playtable_com_content {
  text-align: left;
  padding: 8px 12px;
  box-sizing: inherit;
}
.playtable_comb_radiobtn {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.playtable_comb_radiobtn input {
  margin-right: 9px;
}
.playtable_comb_radiobtn label {
  height: 24px;
  color: #b4b4b4;
  font-size: 12px;
  line-height: 24px;
}
.playtable_favs_root_header {
  height: 28px;
  background-color: #282828;
}
.playtable_favs_root_header span {
  display: flex;
  color: #b4b4b4;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 3;
  padding: 0 12px;
  align-items: center;
  background-color: #282828;
}
.playtable_favs_label {
  line-height: 20px;
  color: #b4b4b4;
  font-size: 12px;
  text-align: center;
  margin-top: 0;
}
.playtable_favs_button {
  transition: all 0.1s ease-in;
  outline: 0;
  margin: 0;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin-top: 12px;
  text-transform: uppercase;
  background: #282828;
  border: 1px solid black;
  color: #b4b4b4;
  font-weight: 600;
  width: calc(100% - 24px);
  margin-left: 15px;
}
.playtable_favs_button:hover {
  background: #b4b4b4;
  color: #282828;
}
.playtable_playtable_root {
  display: flex;
  flex-wrap: wrap;
}
.playtable_pt_element {
  width: 39px;
  margin-left: 6px;
  margin-right: 6px;
}
.playtable_pt_statistics {
  font-size: 10px;
  display: flex;
}
.playtable_stats_hot {
  color: #529900;
  margin-right: 6px;
}
.playtable_stats_cold {
  color: #ea060a;
}
.playtable_pt_value {
  display: block;
  width: 27px;
  height: 27px;
  line-height: 1.7;
  border-radius: 16px;
  text-align: center;
  border: 1px solid black;
  background-color: darkgray;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 6px;
  transition: all 0.1s ease-in;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.playtable_pt_value label {
  cursor: pointer;
}
.playtable_pt_value:hover {
  background-color: #f47f32;
  border: 1px solid black;
  color: black;
  cursor: pointer;
}
.playtable_value_active {
  background-color: #f47f32 !important;
  border: 1px solid black !important;
  color: black;
  border-radius: 16px;
}
/*----TICKET------VERIFICATION-------*/
.ticket_verify_root {
  width: 330px;
  border-radius: 4px;
  background-color: #282828;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 6px 8px;
  margin-bottom: 8px;
  z-index: 105;
  display: flex;
  color: #f47f32;
}
.ticket_verify_root {
  justify-content: flex-start;
  margin-right: 15%;
}
.ticket_verify_label {
  font-size: 12px;
  flex: 1 0 auto;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 0;
  font-size: 90%;
  font-weight: 400;
  line-height: 26px;
}
.ticket_verify_right {
  display: flex;
  justify-content: flex-end;
  margin-left: 12%;
}
.ticket_verify_intup {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  font-size: 12px;
  outline: 0;
  padding: 0 6px;
  color: black;
  height: 24px;
  line-height: 24px;
  background-color: darkgray;
}
.ticket_verify_button {
  cursor: pointer;
  outline: 0;
  color: black;
  background-color: #f47f32;
  height: 26px;
  width: 26px;
  align-items: center;
  border: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 2px;
}

/*-----Ticket Header----*/
.ticket_header_text_text {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f47f32;
  height: 36px;
  align-items: center;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}
.ticket_header_text {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: black;
  text-transform: uppercase;
  line-height: 2.4;
}
.ticket_errors_and_stuff {
  background-color: rgba(0, 0, 0, 0.65);
  color: #f47f32;
  text-align: center;
  justify-content: space-between;
}
.ticket_subheader {
  background-color: #282828;
  padding-left: 12px;
  color: #777;
  font-size: 12px;
  border-bottom: 1px solid darkgray;
  min-height: 28px;
  line-height: 16px;
  display: flex;
}
.ticket_subheader_infos {
  margin-right: 12px;
  display: flex;
}
.ticket_subheader_infos_text {
  margin-right: 3px;
  margin-top: 7px;
}
.ticket_subheader_infos_value {
  font-weight: 700;
  margin-top: 7px;
}
.ticket_subheader_actions {
  margin-left: 55%;
}
.ticket_subheader_actions:hover {
  background-color: rgb(49, 49, 49);
}
.ticket_subheader_clear {
  color: #777;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  background-color: transparent;
  margin: 0;
  border: 0;
  outline: 0;
  margin-right: 6px;
  margin-top: 3px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 2px;
  line-height: 16px;
}
.ticket_subheader_icontrash {
  text-align: center;
  font-size: 12px;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
.ticket_numbers_root {
  margin-top: 1%;
}
.ticket_time {
  color: #999;
  font-size: 10px;
  font-weight: 500;
  margin-right: 12px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 3%;
}
.ticket_numbers_header {
  margin-top: 1%;
  display: flex;
}
.ticket_numbers_header_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  width: 200px;
  text-align: left;
  margin-right: 16px;
  margin-left: 2.5%;
  margin-top: 1%;
  color: #999;
}
.ticket_numbers_header_ballSystem {
  display: flex;
  margin-left: 27%;
}
.ticket_numbers_guess {
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  z-index: 1;
}
.ticket_numbers_outof {
  background-color: #7a7a7a;
  border: 1px solid transparent;
  color: black;
}
.ticket_numbers_guess,
.ticket_numbers_outof {
  border-radius: 50%;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.ticket_numbers_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px;
  padding-right: 0;
  margin-left: 1%;
}
.ticket_ticket_ball {
  display: inline-flex;
  flex-direction: column;
  width: 35px;
  height: 35px;
  margin-right: 3px;
  margin-left: 6px;
  margin-bottom: 9px;
  position: relative;
  color: black;
}
.ticket_ticket_ball span {
  display: block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid darkgray;
  background-color: white;
  font-size: 15px;
  font-weight: 500;
  margin-top: 2px;
  margin-right: 2px;
  transition: all 0.1s ease-in;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.ticket_ticket_ball span:hover {
  background-color: #f47f32;
  color: white;
}
.ticket_combinations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ticket_combinations_single_elem {
  display: flex;
  flex: 0 0 25%;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  height: 30px;
}
input[type="checkbox"] {
  zoom: 1.4;
  border: 1px solid #f47f32;
  background-color: #f47f32;
}
.ticket_combinations_single_elem label {
  font-size: 80%;
  color: darkgray;
  font-weight: 700;
  margin-left: 6%;
  line-height: 25px;
}
.ticket_stake_root {
  border-top: 1px solid darkgray;
  background-color: #282828;
  border-bottom: 1px dashed gray;
  padding: 12px 12px 8px 12px;
}
.ticket_stake_comb_number {
  display: flex;
}
.ticket_stake_comb_label {
  color: #f47f32;
  font-size: 12px;
  font-weight: 650;
  margin-bottom: 8px;
}
.ticket_stake_comb_value {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  color: #f47f32;
  font-size: 12px;
  font-weight: 700;
  margin-right: 2%;
}
.ticket_stake_stake {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticket_stake_stake_buttons {
  align-items: center;
  display: flex;
}
.ticket_stake_your_bet {
  margin-right: 8px;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  font-weight: 500;
  color: darkgray;
}
.ticket_stake_buttons_selectors {
  border: 1px solid black;
  background-color: #282828;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  color: darkgray;
  width: 120px;
  display: flex;
  height: 24px;
  flex: 1 1;
}
.ticket_stake_picker {
  border-right: 1px solid black;
  flex: 1 1;
  justify-content: center;
  text-align: center;
  line-height: 23px;
  border-bottom: none;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  color: #999;
  font-weight: 500;
  font-size: 14px;
}
.ticket_stake_stake_stakes {
  display: flex;
}
.ticket_stake_stakes_label {
  color: darkgray;
  font-weight: 400;
  line-height: 2.5;
  margin-right: 4px;
  margin-left: 8px;
  font-size: 10px;
}
.ticket_stake_stake_input {
  border-radius: 3px;
  color: black;
  margin-bottom: 4px;
  height: 15px;
  padding: 0 8px;
  outline: 0;
  width: 72px;
  line-height: normal;
  background-color: darkgray;
  text-align: right;
  outline: 0;
  border: 1px solid #e3e3e3;
  font-weight: 650;
  font-size: 12px;
  padding: 4px;
  align-items: center;
}
.ticket_stake_tax {
  display: flex;
  justify-content: space-between;
}
.ticket_stake_tax_amount {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  color: darkgray;
  margin-top: 8px;
  width: 50%;
}
.ticket_stake_tax_final {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  color: darkgray;
  margin-top: 8px;
  text-align: right;
}
.ticket_details_root {
  padding: 12px 12px 0 12px;
  background-color: #282828;
}
.ticket_details_maxpot {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
}
.ticket_details_maxpot_label {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  color: darkgray;
}
.ticket_details_maxpot_value {
  display: flex;
}
.ticket_details_maxpot_value_value {
  color: darkgray;
  font-weight: 500;
}
.ticket_details_maxpot_currency {
  color: darkgray;
  font-size: 10px;
  margin-left: 2px;
  font-weight: 400;
}

.ticket_details_minpot {
  margin-top: 8px;
  font-size: 14px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
}
.ticket_details_minpot_label {
  font-weight: 400;
  justify-content: space-between;
  color: darkgray;
}
.ticket_details_minpot_value_value {
  font-weight: 500;
  color: darkgray;
}
.ticket_details_minpot_value_currency {
  color: #777;
  font-size: 10px;
  margin-left: 2px;
  font-weight: 400;
}

.ticket_buttons_place_bet {
  display: flex;
  margin-top: 26px;
  margin-bottom: 18px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.ticket_buttons_place_bet_button {
  cursor: pointer;
  background-color: #f47f32;
  border: 0;
  border-radius: 4px;
  color: black;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
}
.stake_active {
  background-color: #f47f32;
  color: white;
}

.side_mobile_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 78.1vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  margin-top: 14.8vh;
}
.side_mobile_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  position: relative;
}
.side_mobile_dateselector {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  margin-top: 13vh;
}
.side_mobile_date_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  float: right;
}
.ticket_mobile_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
}
.ticket_mobile_wrap {
  width: 340px;
  max-width: 100%;
  border-radius: 4px;
  margin-left: 7%;
}
.playtable_header_mobile_random {
  background-color: #363636;
  padding: 9px;
  margin-right: 3px;
  border-radius: 3px;
}
.playtable_header_mobile_random:last-of-type {
  margin-right: 0;
}
.playtable_header_mobile_icon {
  width: 36px;
  height: 36px;
  font-size: 36px;
}
.favs_favs_wrap {
  width: 100%;
  display: flex;
  border-bottom: 1px dashed whitesmoke;
}
.playtable_favs_sections {
  height: 90px;
  max-height: 231px;
  overflow-y: auto;
}
.favorites_wrap {
  color: whitesmoke;
  display: flex;
  padding: 9px;

  flex-wrap: wrap;
  width: 90%;
}
.favorite_balls {
  width: 27px;
  height: 27px;
  background-color: darkgray;
  color: black;
  text-align: center;
  line-height: 2;
  border-radius: 50%;
  margin: 3px;
}
.favoriotes_trash {
  width: 10%;
  color: #f47f32;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 24px;
  font-size: 18px;
}
.favorites_wrap:hover {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 12px;
}
.favoriotes_trash:hover {
  color: #ea060a;
}

.results_root {
  width: 100%;
  height: 93vh;
  display: grid;
  grid-template-columns: 240px auto 360px;
  grid-template-rows: 36px auto;
  grid-template-areas:
    "sidemenu stuffSelector calendar"
    "sidemenu content calendar";
  grid-column-gap: 1rem;
  column-gap: 1rem;
  grid-row-gap: 0.3rem;
  row-gap: 0.3rem;
}
.results_side_mobile {
  grid-area: sidemenumobile;
  display: none;
}
.results_side_menu {
  grid-area: sidemenu;
}
.results_stuff_selector {
  grid-area: stuffSelector;
  z-index: 1;
  position: fixed;
  width: calc(100% - 640px);
  height: 36px;
  margin-left: 260px;
}
.results_content {
  grid-area: content;
}
.results_calendar_side {
  grid-area: calendar;
}
@media all and (max-width: 1280px) {
  .results_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 52px auto auto;
    grid-template-areas: "sidemenumobile" "calendar" "content";
    grid-row-gap: 0;
    row-gap: 0;
    grid-column-gap: 0;
    column-gap: 0;
  }
  .results_side_mobile {
    display: block;
  }
  .results_side_menu {
    display: none;
  }
  .results_stuff_selector {
    display: none;
  }
}
@media all and (max-width: 1366px) {
  .blanker {
    height: 8vh !important;
  }
}

.content_results_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px minmax(auto, calc(100vh - 15vh));
  grid-template-areas: "resultMinih" "resultContentLisst";
  grid-row-gap: 0.1rem;
  row-gap: 0.1rem;
}
.content_results_miniheader {
  grid-area: resultMinih;
}
.content_results_list {
  grid-area: resultContentLisst;
}
@media all and (max-width: 1440px) {
  .content_results_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px auto;
    grid-template-areas: "miniHeader" "contentList";
    grid-row-gap: 0;
    row-gap: 0;
  }

  .content_results_miniheader {
    grid-area: miniHeader;
  }
  .content_results_list {
    grid-area: contentList;
  }
}

/*-----STUFF SELECTOR--------*/
.stuff_selector_root {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  background-color: #282828;
}
.results_stuff_selector_item {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 3px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
}
.results_stuff_selector_item:hover {
  background-color: #666262;
}
.stuff_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}

/*----SIDE MENU----*/
.side_menu_top_idx {
  background-color: #282828;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid black;
}

/*---CONTENT AREA ----*/
.content_results_miniheader {
  width: calc(100% - 630px);
  display: flex;
  align-items: center;
  background-color: #282828;
  font-size: 10px;
  color: #b4b4b4;
  position: -webkit-sticky;
  position: sticky;
  z-index: 40;
  margin-top: 6px;
  height: 30px;
  position: fixed;
}
@media all and (max-width: 1280px) {
  .content_results_miniheader {
    width: 100%;
    position: unset;
  }
}
.content_results_minih_date {
  width: 10%;
  margin-left: 9px;
}
.content_results_minih_label {
  width: 20%;
}
.content_results_minih_nums {
  width: 69%;
}
/* .content_results_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.content_results_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.content_results_list::-webkit-scrollbar-thumb {
  background-color: #f47f32;
} */

/*----single row results---*/
.content_results_single_row_root {
  display: flex;
  color: #333;
  font-size: 12px;
  border-bottom: 1px solid black;
  background-color: #282828;
  height: 30px;
}
.content_results_single_row_date {
  width: 10%;
  margin-left: 9px;
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  line-height: 3;
}
.content_results_single_row_label {
  width: 20%;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.5;
  text-align: left;
  color: whitesmoke;
}
.results_single_row_name {
  width: 50%;
}
.results_single_row_system {
  display: flex;
}
.results_single_row_guess {
  margin-top: 6px;
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
}
.results_single_row_outof {
  margin-top: 6px;
  background-color: #7a7a7a;
  color: black;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
  border: 1px solid transparent;
}
.content_results_single_row_numbers {
  display: flex;
  flex-wrap: wrap;
}
.content_results_single_row_num {
  margin-top: 3px;
  display: inline-block;
  background-color: rgba(244, 127, 50, 0.8);
  height: 24px;
  width: 24px;
  margin-right: 6px;
  text-align: center;
  line-height: 2;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  color: black;
}

@media all and (max-width: 1540px) {
  .content_results_single_row_root {
    display: block;
    color: #333;
    height: 120px;
  }
  .content_results_single_row_date {
    width: 100%;
    height: 30px;
    margin-left: 9px;
  }
  .content_results_single_row_label {
    width: 100%;
    height: 30px;
    margin-left: 9px;
  }
  .results_single_row_name {
    width: auto;
    margin-right: 9px;
  }
  .content_results_single_row_numbers {
    height: 30px;
    width: 100%;
    margin-left: 9px;
  }
}

/*---CALENDAR---*/
.calendar_root {
  display: block;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  z-index: 50;
  position: fixed;
  width: 350px;
}
@media all and (max-width: 1440px) {
  .calendar_root {
    position: unset;
    width: 100%;
  }
}
.calendar_selector_button {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
  height: 20px;
  margin-top: 1px;
}
.calendar_selector_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.calendar_selector_button:hover {
  background-color: #666262;
}
.calendar_selector_button a {
  all: unset;
}

.hour_selector_root {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
  z-index: 50;
}
.hour_selector_button {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
}
.hour_selector_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.hour_selector_button:hover {
  background-color: #666262;
}
.hour_selector_is_active a {
  all: unset;
}

.modal-ticket {
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  overflow: hidden;
}
.modal-container {
  width: 656px;
  max-width: 100%;
  background-color: #2e2e2e;
  border-radius: 4px;
  margin: 120px auto 60px;
  position: relative;
}
.modal-ticket-header {
  display: flex;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid black;
  background-color: #f47f32;
}
.modal-ticket-header-title {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 9px;
  text-transform: uppercase;
  color: #333;
}
.modal-ticket-header-icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: #333;
  margin-right: 9px;
  cursor: pointer;
}
.modal-ticket-subheader {
  background-color: #2e2e2e;
  padding-left: 12px;
  color: whitesmoke;
  font-size: 12px;
  border-bottom: 1px solid black;
  min-height: 28px;
  line-height: 16px;
  display: flex;
}
.modal-ticket-subheader-numbers {
  width: 30%;
  margin-top: 6px;
}
.modal-ticket-subheader-numbers span {
  font-weight: 200;
  font-size: 13px;
  color: whitesmoke;
  margin-left: 3px;
}
.modal-ticket-subheader-cota {
  width: 68%;
  margin-top: 6px;
}
.modal-ticket-subheader-cota span {
  font-weight: 400;
  font-size: 13px;
  color: whitesmoke;
  margin-left: 3px;
}
.modal-ticket-content {
  display: flex;
  padding-bottom: 1px;
  border-radius: 0 0 4px 4px;
  width: 100%;
}
.modal-ticket-left-wrap {
  border-right: 1px solid black;
  position: inherit;
  width: 48%;
  padding: 3px 6px;
}
.modal-ticket-left {
  margin-top: 9px;
  background-color: #363636;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 3px;
  color: whitesmoke;
}
.modal-ticket-right {
  display: block;
  overflow: visible;
  width: 50%;
}
.modal-ticket-serial {
  padding: 12px;
  line-height: 20px;
  text-align: center;
  color: whitesmoke;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  width: 300px;
}
.modal-ticket-serial svg {
  width: 300px !important;
}
.modal-ticket-serial > svg > rect {
  fill: rgb(46, 46, 46) !important;
}
.modal-ticket-serial > svg > g {
  fill: whitesmoke !important;
}
.modal-ticket-serial > svg > g > text {
  color: whitesmoke !important;
  font-size: 24px !important;
}
.modal-ticket-mize {
  border-top: 1px solid black;
  padding: 12px 0 8px 0;
}
.modal-ticket-mize-ct {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-miza {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-ct-num {
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
}
.modal-ticket-mize-ct-num span {
  font-weight: 400;
  color: whitesmoke;
  font-size: 12px;
}
.modal-ticket-mize-taxa {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-mizataxa {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-buttons {
  border-top: 1px dashed black;
  padding: 12px 0 8px 0;
}
.modal-ticket-pot-win {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  margin-left: 6px;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  margin-right: 6px;
}
.modal-ticket-pot-win-win span {
  font-weight: 400;
  color: whitesmoke;
  font-size: 12px;
  margin-right: 6px;
}
.modal-tickets-btns {
  display: flex;
  margin-top: 6%;
}
.modal-ticket-close-btn {
  cursor: pointer;
  background-color: #ea060a;
  border: 0;
  border-radius: 4px;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
  margin-right: 8px;
  border: 1px solid #777;
  color: whitesmoke;
  background-color: transparent;
  margin-left: 6px;
}
.modal-ticket-print-btn {
  cursor: pointer;
  background-color: #f47f32;
  border: 0;
  border-radius: 4px;
  color: #fff;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
  margin-right: 6px;
}
.modal-ticket-close-btn:hover {
  background-color: #e3e3e3;
}
.modal-ticket-print-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.bar-bar-code {
  height: 10px;
}
.modal-ticket-date-time {
  color: whitesmoke !important;
  font-size: 10px;
  font-weight: 300;
  margin-right: 12px;
  text-transform: uppercase;
  text-align: left;
  padding: 6px;
}
.modal-ticket-name {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  width: 200px;
  text-align: left;
  padding: 6px;
  color: whitesmoke;
}

@media all and (max-width: 786px) {
  .modal-ticket {
    top: 100 !important;
    left: 100 !important;
    height: 93vh;
    margin-top: 7vh;
    background-color: rgba(0, 0, 0, 0.48);
  }
  .modal-container {
    all: unset;
    display: grid !important;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "mob_head" "mob_subhead" "mob_cntnt";
    background-color: rgb(46, 46, 46);
  }
  .modal-ticket-header {
    grid-area: mob_head;
  }

  .modal-ticket-subheader {
    grid-area: mob_subhead;
    justify-content: space-evenly;
    width: 100%;
  }
  .modal-ticket-subheader-numbers {
    width: auto;
    float: left;
  }
  .modal-ticket-subheader-cota {
    width: auto;
    float: right;
  }

  .modal-ticket-content {
    grid-area: mob_cntnt;
  }
  .modal-ticket-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: "mob_stuff" "mob_numbers";
  }
  .modal-ticket-left {
    grid-area: mob_numbers;
  }
  .modal-ticket-right {
    grid-area: mob_stuff;
  }
  .modal-ticket-right {
    width: 100% !important;
  }
  .modal-ticket-serial {
    width: 100% !important;
    padding: 0 !important;
    margin: auto !important;
  }
  .modal-ticket-left-wrap {
    width: 100% !important;
    margin-bottom: 18px !important;
  }
}

.side_mobile_root {
  display: grid;
  width: 100%;
  height: 42px;
  grid-template-columns: 72% auto;
  grid-template-rows: 100%;
  grid-template-areas: "mobile_lotteries mobile_dates";
  position: fixed;
}
.side_mobile_lottery_buttons {
  grid-area: mobile_lotteries;
}
.side_mobile_date_selector {
  grid-area: mobile_dates;
}
.side_mobile_root {
  z-index: 500;
  background-color: #fa741b;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%), 0 8px 0 0 #f47f32;
  border-radius: 0;
}
.side_mobile_lottery_buttons {
  border-right: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  outline: 0;
  padding: 0 16px;
  z-index: 2;
  background-color: #fa741b;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.side_mobile_lottery_label {
  display: flex;
}
.side_lottery_label_icon {
  margin-right: 4px;
  color: black;
  vertical-align: middle;
  flex: 0 0 20px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.side_lottery_label_label {
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-transform: uppercase;
  line-height: 1.8;
}
.side_mobile_date_selector {
  display: flex;
  align-items: center;
  flex: 1 1;
  cursor: pointer;
}
.side_mobile_date_icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  flex: 0 0 20px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 22px;
  color: black;
}
.side_mobile_date_label {
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.side_ticket_root {
  width: 100%;
  height: 50px;
  bottom: 0;
  display: grid;
  position: fixed;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "queueL betslipL potwinL" "queueV betslipV potwinV";
  background-color: #f47f32;
  border-top: 2px solid #fa741b;
  border-radius: 6px;
  cursor: pointer;
}
.side_ticket_queue_label {
  grid-area: queueL;
}
.side_ticket_betslip_label {
  grid-area: betslipL;
}
.side_ticket_potwin_label {
  grid-area: potwinL;
}
.side_ticket_queue {
  grid-area: queueV;
}
.side_ticket_betslip {
  grid-area: betslipV;
}
.side_ticket_potwin {
  grid-area: potwinV;
}
.side_ticket_queue_label,
.side_ticket_betslip_label,
.side_ticket_potwin_label {
  opacity: 0.8;
  font-size: 12px;
  line-height: 18px;
  margin-left: 9px;
}
.side_ticket_queue,
.side_ticket_betslip,
.side_ticket_potwin {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-left: 9px;
}

.register_root {
  height: 94vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto 21% 21% 21% auto;
  grid-template-rows: 74vh 20vh;
  grid-template-areas:
    "blank1 form1 form2 form3 blank2"
    "btn btn btn btn btn";
  grid-gap: 1rem;
}
.blank_space1 {
  grid-area: blank1;
}
.blank_space2 {
  grid-area: blank2;
}
.first_form {
  grid-area: form1;
}
.second_form {
  grid-area: form2;
}
.third_form {
  grid-area: form3;
}
.reg_button {
  grid-area: btn;
}

@media all and (max-width: 1280px) {
  .register_root {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-template-rows: 74vh 20vh;
    grid-template-areas:
      "form1 form2 form3"
      "btn btn btn";
    grid-gap: 0rem;
  }
  .blank_space1 {
    display: none;
  }
  .blank_space2 {
    display: none;
  }
}
@media all and (max-width: 990px) {
  .register_root {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 74vh 37vh 20vh;
    grid-template-areas:
      "form1 form2"
      "form3 form3"
      "btn btn";
  }
}
@media all and (max-width: 670px) {
  .register_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 74vh 74vh 74vh 20vh;
    grid-template-areas: "form1" "form2" "form3" "btn";
  }
}

/*First Form Css*/
.first_form {
  border-radius: 4px;
  background-color: #333;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px;
  margin-top: 30px;
    z-index: 1;
}
.form1_title {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}
.form1_title_mins {
  color: #999;
  font-size: 12px;
  text-align: right;
  line-height: 16px;
}
.form1_basics {
  margin-top: 12px;
}
.form1_basics_title {
  color: white;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}
.form_field_root {
  margin-bottom: 12px;
}
.input__label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: white;
  margin-bottom: 8px;
}
.input__field {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 100%;
  color: #333;
  font-size: 14px;
  margin-bottom: 4px;
  height: 32px;
  padding: 0 8px;
  outline: 0;
}
.error_field {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  align-items: flex-start;
  color: #f47f32;
}

/*Second Form CSS*/
.second_form {
  border-radius: 4px;
  background-color: #333;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px;
  margin-top: 30px;
        z-index: 1;
}
.form2_title {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}
.form2_basics {
  margin-top: 12px;
}
.label_bonus_code {
  font-size: 14px;
  color: wheat;
  font-weight: 400;
}

/*Third Form CSS*/
.third_form {
  border-radius: 4px;
  background-color: #333;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px;
  margin-top: 30px;
        z-index: 1;
}
.form3_ph1 {
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}
.form3_ph2 {
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}
.form3_ph3 {
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}

.reg_button {
  width: 135px;
  margin: auto;
}
.reg_button_button {
  cursor: pointer;
  line-height: 1;
  transition: all 0.1s ease-in;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #f47f32;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  padding: 0 12px;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  max-width: 144px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.profile-root {
  width: 100%;
  height: 100%;
  display: flex;
}
.profile-side-menu {
  height: 33%;
  width: 210px;
  z-index: 12;
}
.profile-content {
  height: 100%;
  width: 80%;
  z-index: 12;
}
.profile-side-root {
  width: 100%;
  height: 100%;
  margin-left: 3%;
}
.nav-sider-wrapper {
  background-color: #282828;
  border-radius: 4px;
}
.nav-sider-tree {
  display: block;
  padding: 0;
}
.nav-sider-tree-item {
  display: flex;
  transition: all 0.2s ease;
  cursor: pointer;
  min-height: 36px;
  line-height: 33px;
  font-size: 13px;
  border-bottom: 1px solid black;
  font-weight: 500;
  color: #b4b4b4;
  text-transform: uppercase;
  position: relative;
}
.nav-sider-tree-item a {
  all: unset;
}
.nav-sider-tree-item:hover {
  background-color: #666262;
}
.nav-sider-tree-item-icon {
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 6px;
  color: #b4b4b4;
  vertical-align: middle;
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.player-tickets-root {
  width: 100%;
  height: 100%;
}
.player-tickets-dateSelector-btns {
  width: 100%;
  height: 3%;
  background-color: #282828;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 6px;
  display: flex;
}
.player-ticket-date-selector-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-right: 1px solid #333;
  font-size: 14px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
}
.player-ticket-date-selector-btn:hover {
  background-color: #666262;
}
.is_date_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.nav-sider-tree-item-label a {
  all: unset;
}
.player-tickets-header {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 6px;
}
.player-tickets-label-icon {
  color: #b4b4b4;
  vertical-align: middle;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.player-tickets-label {
  color: #b4b4b4;
  font-size: 21px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  margin-left: 9px;
  line-height: 30px;
}
.player-tickets-ongoing {
  width: 100%;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 6px;
  background-color: #282828;
  border-radius: 4px;
  min-height: 33%;
  max-height: 40%;
  overflow-y: auto;
}
.player-t-ongoing-header {
  width: 100%;
  height: 6%;
  margin-top: 0.6%;
  display: flex;
}
.player-t-o_h-icon {
  color: #b4b4b4;
  margin-top: 3px;
  margin-right: 9px;
  margin-left: 9px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.player-t-o_h-label {
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}
.player-t-ongoing-subheader {
  width: 100%;
  height: 5%;
  margin-top: 0.6%;
  display: flex;
  background-color: #282828;
  color: #b4b4b4;
  font-size: 12px;
  text-transform: uppercase;
}
.player-t-ongoing-content {
  max-height: 33vh;
}
.__lb1 {
  width: 9%;
  text-align: left;
  margin-left: 3px;
}
.__lb2 {
  width: 10%;
  text-align: left;
  margin-left: 3px;
}
.__lb3 {
  width: 51%;
  text-align: left;
  margin-left: 3px;
}
.__lb4 {
  width: 10%;
  text-align: left;
  margin-left: 3px;
}
.__lb5 {
  width: 9%;
  text-align: left;
  margin-left: 3px;
}
.player-tickets-finished {
  width: 100%;
  margin-top: 27px;
  margin-left: 60px;
  margin-right: 6px;
  background-color: #282828;
  border-radius: 4px;
  min-height: 33%;
  max-height: 40%;
  overflow-y: auto;
}
.profile_history-row {
  display: flex;
  height: 150px;
}
.profile_history-status {
  display: flex;
}
.profile_history__row {
  width: 100%;
  height: 30px;
  margin-top: 0.6%;
  display: flex;
  background-color: #282828;
  color: #b4b4b4;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}
.profile_history-status-icon-x {
  color: #ea060a;
  margin-left: 3px;
  margin-right: 3px;
  width: 18px !important;
  height: 18px !important;
  margin-top: 6px;
}
.profile_history-status-icon-v {
  color: green;
  margin-left: 3px;
  margin-right: 3px;
  width: 18px !important;
  height: 18px !important;
  margin-top: 6px;
}
.profile_history-status-icon-w {
  color: #b4b4b4;
  margin-left: 3px;
  margin-right: 3px;
  width: 18px !important;
  height: 18px !important;
  margin-top: 6px;
}
.profile_history-status-date {
  font-size: 10px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 9px;
}
.profile_history-tip {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile-history-cod-bilet {
  font-size: 14px;
  color: #b4b4b4;
  font-weight: 600;
  margin-top: 3px;
}
.profile-history-cota {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile-history-cota {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile_history-mize {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile_history-mize span {
  font-size: 10px;
  color: #b4b4b4;
}
.profile_history-plata {
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 6px;
}
.profile_history-plata span {
  font-size: 10px;
  color: #b4b4b4;
}

@media all and (max-width: 1280px) {
  .profile-root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: "profile_side" "profile_content";
    grid-row-gap: 0.4rem;
    row-gap: 0.4rem;
  }
  .profile-side-menu {
    grid-area: profile_side;
    width: 100%;
  }
  .profile-content {
    grid-area: profile_content;
    width: 100%;
  }
  .nav-sider-tree {
    display: flex !important;
    flex-wrap: wrap;
  }
  .nav-sider-tree-item {
    width: calc(50% - 1px);
    border: none;
    border-bottom: 1px solid black;
  }
  .nav-sider-tree-item:last-of-type {
    border: none;
  }
  .profile-side-root {
    margin: 0;
  }
  .mobile_bordered {
    border-right: 1px solid black;
  }
  .player-tickets-dateSelector-btns {
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
  .player-tickets-header {
    width: 90%;
    margin: 0;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .player-tickets-ongoing {
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
  .player-t-ongoing-subheader {
    margin-top: 7px;
    border-bottom: 1px solid #b4b4b4;
  }
  .__lb1 {
    width: 50px;
  }
  .profile_history-status-date {
    display: none !important;
  }
  .__lb2 {
    display: none;
  }
  .__lb3 {
    width: 100px !important;
  }
  .__lb4 {
    width: 90px !important;
  }
  .__lb5 {
    display: none;
  }
  .player-t-ongoing-content > div {
    border-bottom: 1px solid black;
  }
  .player-tickets-finished {
    width: 98%;
    margin: 0;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 3%;
  }
}

.verify_ticket_root_page {
  display: block;
  width: 85%;
  height: 90px;
  margin-left: 5%;
  background-color: #282828;
  border-radius: 6px;
  padding: 9px;
}
.verfitopper {
  margin-bottom: 12px;
  color: transparent;
}

