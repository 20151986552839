.verify_ticket_root_page {
  display: block;
  width: 85%;
  height: 90px;
  margin-left: 5%;
  background-color: #282828;
  border-radius: 6px;
  padding: 9px;
}
.verfitopper {
  margin-bottom: 12px;
  color: transparent;
}
