/*First Form Css*/
.first_form {
  border-radius: 4px;
  background-color: #333;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px;
  margin-top: 30px;
    z-index: 1;
}
.form1_title {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}
.form1_title_mins {
  color: #999;
  font-size: 12px;
  text-align: right;
  line-height: 16px;
}
.form1_basics {
  margin-top: 12px;
}
.form1_basics_title {
  color: white;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 16px;
}
.form_field_root {
  margin-bottom: 12px;
}
.input__label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: white;
  margin-bottom: 8px;
}
.input__field {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 100%;
  color: #333;
  font-size: 14px;
  margin-bottom: 4px;
  height: 32px;
  padding: 0 8px;
  outline: 0;
}
.error_field {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #f47f32;
}

/*Second Form CSS*/
.second_form {
  border-radius: 4px;
  background-color: #333;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px;
  margin-top: 30px;
        z-index: 1;
}
.form2_title {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}
.form2_basics {
  margin-top: 12px;
}
.label_bonus_code {
  font-size: 14px;
  color: wheat;
  font-weight: 400;
}

/*Third Form CSS*/
.third_form {
  border-radius: 4px;
  background-color: #333;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 32px 40px;
  margin-top: 30px;
        z-index: 1;
}
.form3_ph1 {
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}
.form3_ph2 {
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}
.form3_ph3 {
  color: white;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 38px;
}

.reg_button {
  width: 135px;
  margin: auto;
}
.reg_button_button {
  cursor: pointer;
  line-height: 1;
  transition: all 0.1s ease-in;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #f47f32;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  padding: 0 12px;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  max-width: 144px;
  margin-bottom: 16px;
  margin-top: 8px;
}
