.register_root {
  height: 94vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto 21% 21% 21% auto;
  grid-template-rows: 74vh 20vh;
  grid-template-areas:
    "blank1 form1 form2 form3 blank2"
    "btn btn btn btn btn";
  grid-gap: 1rem;
}
.blank_space1 {
  grid-area: blank1;
}
.blank_space2 {
  grid-area: blank2;
}
.first_form {
  grid-area: form1;
}
.second_form {
  grid-area: form2;
}
.third_form {
  grid-area: form3;
}
.reg_button {
  grid-area: btn;
}

@media all and (max-width: 1280px) {
  .register_root {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-template-rows: 74vh 20vh;
    grid-template-areas:
      "form1 form2 form3"
      "btn btn btn";
    grid-gap: 0rem;
  }
  .blank_space1 {
    display: none;
  }
  .blank_space2 {
    display: none;
  }
}
@media all and (max-width: 990px) {
  .register_root {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 74vh 37vh 20vh;
    grid-template-areas:
      "form1 form2"
      "form3 form3"
      "btn btn";
  }
}
@media all and (max-width: 670px) {
  .register_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 74vh 74vh 74vh 20vh;
    grid-template-areas: "form1" "form2" "form3" "btn";
  }
}
