.modal-ticket {
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  overflow: hidden;
}
.modal-container {
  width: 656px;
  max-width: 100%;
  background-color: #2e2e2e;
  border-radius: 4px;
  margin: 120px auto 60px;
  position: relative;
}
.modal-ticket-header {
  display: flex;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid black;
  background-color: #f47f32;
}
.modal-ticket-header-title {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 9px;
  text-transform: uppercase;
  color: #333;
}
.modal-ticket-header-icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: #333;
  margin-right: 9px;
  cursor: pointer;
}
.modal-ticket-subheader {
  background-color: #2e2e2e;
  padding-left: 12px;
  color: whitesmoke;
  font-size: 12px;
  border-bottom: 1px solid black;
  min-height: 28px;
  line-height: 16px;
  display: flex;
}
.modal-ticket-subheader-numbers {
  width: 30%;
  margin-top: 6px;
}
.modal-ticket-subheader-numbers span {
  font-weight: 200;
  font-size: 13px;
  color: whitesmoke;
  margin-left: 3px;
}
.modal-ticket-subheader-cota {
  width: 68%;
  margin-top: 6px;
}
.modal-ticket-subheader-cota span {
  font-weight: 400;
  font-size: 13px;
  color: whitesmoke;
  margin-left: 3px;
}
.modal-ticket-content {
  display: flex;
  padding-bottom: 1px;
  border-radius: 0 0 4px 4px;
  width: 100%;
}
.modal-ticket-left-wrap {
  border-right: 1px solid black;
  position: inherit;
  width: 48%;
  padding: 3px 6px;
}
.modal-ticket-left {
  margin-top: 9px;
  background-color: #363636;
  height: fit-content;
  border-radius: 3px;
  color: whitesmoke;
}
.modal-ticket-right {
  display: block;
  overflow: visible;
  width: 50%;
}
.modal-ticket-serial {
  padding: 12px;
  line-height: 20px;
  text-align: center;
  color: whitesmoke;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  width: 300px;
}
.modal-ticket-serial svg {
  width: 300px !important;
}
.modal-ticket-serial > svg > rect {
  fill: rgb(46, 46, 46) !important;
}
.modal-ticket-serial > svg > g {
  fill: whitesmoke !important;
}
.modal-ticket-serial > svg > g > text {
  color: whitesmoke !important;
  font-size: 24px !important;
}
.modal-ticket-mize {
  border-top: 1px solid black;
  padding: 12px 0 8px 0;
}
.modal-ticket-mize-ct {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-miza {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-ct-num {
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
}
.modal-ticket-mize-ct-num span {
  font-weight: 400;
  color: whitesmoke;
  font-size: 12px;
}
.modal-ticket-mize-taxa {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-mizataxa {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-buttons {
  border-top: 1px dashed black;
  padding: 12px 0 8px 0;
}
.modal-ticket-pot-win {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  margin-left: 6px;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  margin-right: 6px;
}
.modal-ticket-pot-win-win span {
  font-weight: 400;
  color: whitesmoke;
  font-size: 12px;
  margin-right: 6px;
}
.modal-tickets-btns {
  display: flex;
  margin-top: 6%;
}
.modal-ticket-close-btn {
  cursor: pointer;
  background-color: #ea060a;
  border: 0;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
  margin-right: 8px;
  border: 1px solid #777;
  color: whitesmoke;
  background-color: transparent;
  margin-left: 6px;
}
.modal-ticket-print-btn {
  cursor: pointer;
  background-color: #f47f32;
  border: 0;
  border-radius: 4px;
  color: #fff;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
  margin-right: 6px;
}
.modal-ticket-close-btn:hover {
  background-color: #e3e3e3;
}
.modal-ticket-print-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.bar-bar-code {
  height: 10px;
}
.modal-ticket-date-time {
  color: whitesmoke !important;
  font-size: 10px;
  font-weight: 300;
  margin-right: 12px;
  text-transform: uppercase;
  text-align: left;
  padding: 6px;
}
.modal-ticket-name {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  width: 200px;
  text-align: left;
  padding: 6px;
  color: whitesmoke;
}

@media all and (max-width: 786px) {
  .modal-ticket {
    top: 100 !important;
    left: 100 !important;
    height: 93vh;
    margin-top: 7vh;
    background-color: rgba(0, 0, 0, 0.48);
  }
  .modal-container {
    all: unset;
    display: grid !important;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "mob_head" "mob_subhead" "mob_cntnt";
    background-color: rgb(46, 46, 46);
  }
  .modal-ticket-header {
    grid-area: mob_head;
  }

  .modal-ticket-subheader {
    grid-area: mob_subhead;
    justify-content: space-evenly;
    width: 100%;
  }
  .modal-ticket-subheader-numbers {
    width: auto;
    float: left;
  }
  .modal-ticket-subheader-cota {
    width: auto;
    float: right;
  }

  .modal-ticket-content {
    grid-area: mob_cntnt;
  }
  .modal-ticket-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: "mob_stuff" "mob_numbers";
  }
  .modal-ticket-left {
    grid-area: mob_numbers;
  }
  .modal-ticket-right {
    grid-area: mob_stuff;
  }
  .modal-ticket-right {
    width: 100% !important;
  }
  .modal-ticket-serial {
    width: 100% !important;
    padding: 0 !important;
    margin: auto !important;
  }
  .modal-ticket-left-wrap {
    width: 100% !important;
    margin-bottom: 18px !important;
  }
}
