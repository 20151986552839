.side_mobile_root {
  display: grid;
  width: 100%;
  height: 42px;
  grid-template-columns: 72% auto;
  grid-template-rows: 100%;
  grid-template-areas: "mobile_lotteries mobile_dates";
  position: fixed;
}
.side_mobile_lottery_buttons {
  grid-area: mobile_lotteries;
}
.side_mobile_date_selector {
  grid-area: mobile_dates;
}
.side_mobile_root {
  z-index: 500;
  background-color: #fa741b;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%), 0 8px 0 0 #f47f32;
  border-radius: 0;
}
.side_mobile_lottery_buttons {
  border-right: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  outline: 0;
  padding: 0 16px;
  z-index: 2;
  background-color: #fa741b;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.side_mobile_lottery_label {
  display: flex;
}
.side_lottery_label_icon {
  margin-right: 4px;
  color: black;
  vertical-align: middle;
  flex: 0 0 20px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.side_lottery_label_label {
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-transform: uppercase;
  line-height: 1.8;
}
.side_mobile_date_selector {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
}
.side_mobile_date_icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  flex: 0 0 20px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 22px;
  color: black;
}
.side_mobile_date_label {
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.side_ticket_root {
  width: 100%;
  height: 50px;
  bottom: 0;
  display: grid;
  position: fixed;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 50% 50%;
  grid-template-areas: "queueL betslipL potwinL" "queueV betslipV potwinV";
  background-color: #f47f32;
  border-top: 2px solid #fa741b;
  border-radius: 6px;
  cursor: pointer;
}
.side_ticket_queue_label {
  grid-area: queueL;
}
.side_ticket_betslip_label {
  grid-area: betslipL;
}
.side_ticket_potwin_label {
  grid-area: potwinL;
}
.side_ticket_queue {
  grid-area: queueV;
}
.side_ticket_betslip {
  grid-area: betslipV;
}
.side_ticket_potwin {
  grid-area: potwinV;
}
.side_ticket_queue_label,
.side_ticket_betslip_label,
.side_ticket_potwin_label {
  opacity: 0.8;
  font-size: 12px;
  line-height: 18px;
  margin-left: 9px;
}
.side_ticket_queue,
.side_ticket_betslip,
.side_ticket_potwin {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-left: 9px;
}
