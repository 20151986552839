/*TOP IDX*/
.top_idx_lotteries {
  background-color: #282828;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid black;
}
.top_idx_single_element {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid black;
  text-align: center;
  cursor: pointer;
  display: flex;
}
.top_idx_single_element:hover {
  background-color: #666262;
}
.top_idx_logo {
  width: 30px;
  display: flex;
  align-items: center;
}
.top_idx_span {
  width: calc(100% - 110px);
  margin-left: 6px;
  color: whitesmoke;
  font-size: 12px;
  font-weight: 500;
  line-height: 3.3;
  text-align: left;
}
.top_idx_logo_i {
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 45%;
  border: 1px solid black;
}
.top_idx_balls_logo {
  width: 46px;
  display: flex;
  align-items: center;
}
.top_idx_guess {
  background-color: rgb(207, 207, 207);
  border: 1px solid black;
  color: black;
  margin-right: -2px;
  z-index: 1;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.top_idx_total {
  background-color: #7a7a7a;
  color: black;
  border: 1px solid black;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.top_idx_camera {
  width: 30px;
}
.top_idx_camera_i {
  color: white;
  padding-top: 9px;
  padding-left: 3px;
}
/*endOf TOP IDX --> GENERIC SIDE MENU*/
/*----------SIDE TABS---------*/
.generic_side_tabs {
  width: 100%;
  display: flex;
  background-color: #282828;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 36px;
  display: flex;
  z-index: 50;
  position: relative;
  text-transform: uppercase;
}
.generic_side_tab {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 4px;
  border-right: 1px solid black;
  font-size: 12px;
  color: #f47f32;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}
.generic_side_tab:hover {
  background-color: #666262;
}
.generic_side_tab a {
  all: unset;
}
.generic_side_tab_active {
  border-bottom: 2px solid #f47f32;
  background-color: #282828;
}
/*----------GENERIC LIST + SUBITEM---------*/
.generic_list {
  background-color: #282828;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}
.generic_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.generic_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.generic_list::-webkit-scrollbar-thumb {
  background-color: #f47f32;
}

.all_button_country_item {
  text-align: left;
  border-bottom: 1px solid black;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: whitesmoke;
  width: 100%;
}
.all_button_span {
  text-transform: uppercase;
  margin-left: 18px;
  width: 66%;
}
.all_button_country_item:hover {
  background-color: #666262;
}
.all_button_icon {
  border-radius: 50%;
  margin-top: 2.4%;
  margin-left: 1%;
}
.all_button_country_item_active {
  background-color: #666262;
  border-left: 2px solid #f47f32;
}
.all_button_camera {
  padding-top: 3px;
}
.generic_country_subitem {
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid black;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  cursor: pointer;
  color: #b4b4b4;
  text-transform: uppercase;
  width: 100%;
  display: flex;
}
.generic_subitem_spaning {
  width: 80%;
  margin-left: 15px;
}
.subitem_active {
  background-color: rgba(234, 6, 10, 0.05);
}
.generic_country_subitem:hover {
  background-color: darkgray;
  color: darkslategrey;
}
.generic_subitem_camera {
  padding-top: 3px;
}
/* .generic_country_subitem span {
  margin-left: 15px;
} */
/*--------SideMenuForSystems----*/
.generic_list_root_sys {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 8px 12px;
  z-index: 10;
}
.system_flexin_half {
  width: 50%;
}
.generic_list_all_button_sys {
  display: inline-flex;
  flex: 0 0 100%;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.generic_system_item_label {
  font-size: 14px;
  color: #b4b4b4;
  margin-left: 3px;
}
.generic_system_item_span {
  min-height: 16px;
  vertical-align: middle;
  color: #b4b4b4;
  text-align: left;
  font-weight: bold;
  line-height: 2;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: #b4b4b4;
  text-transform: uppercase;
}

.generic_list_rest_items_sys {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px 24px 0px 0px;
  z-index: 10;
}
.generic_system_item_root {
  display: inline-flex;
  flex: 0 0 50%;
  padding-right: 21px;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/*  width: calc(100% - 650px);
  height: 36px;
  margin-left: 265px;
  margin-right: 360px;
}*/

/*---------CONTENT <> mini header---------*/
.content_mini_header {
  width: calc(100% - 650px);
  display: flex;
  align-items: center;
  background-color: #363636;
  font-size: 10px;
  color: #b4b4b4;
  position: fixed;
  z-index: 40;
  margin-top: 6px;
  height: 30px;
  margin-left: 8px;
}
.content_miniheader_label {
  width: 60%;
  margin-left: 12px;
}
.content_miniheader_odds {
  width: 41%;
  display: flex;
}
.content_miniheader_odd {
  width: 12.5%;
  text-align: center;
}
/*---------CONTENT <> list--------*/
/* .infinite-scroll-component::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.infinite-scroll-component::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: #f47f32;
}
*/
/* .content_root {
  z-index: 1;
} */
.content_list {
  width: 98.9%;
  margin-left: 0.5%;
}
/*---------CONTENT <> SINGLE ROW*/
.content_single_row_root {
  padding: 6px;
  color: #333;
  font-size: 12px;
  border-bottom: 1px solid black;
  background-color: #282828;
  cursor: pointer;
}
.content_single_row_date {
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  line-height: 2;
}
.content_single_row_shopCode {
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  line-height: 2;
}
.content_single_row_name {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
  text-align: left;
  color: whitesmoke;
}
.content_single_row_system {
  display: flex;
}
.content_row_system_draw {
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
}
.content_row_system_outOf {
  background-color: #7a7a7a;
  color: black;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
  border: 1px solid transparent;
}
.content_single_row_odds {
  display: flex;
}
.content_single_row_odd {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  width: 12.5%;
  color: #b4b4b4;
}

/*---PLAY TABLE------*/
.play_table_header_root {
  background: #3d3d3d;
  display: flex;
  width: 100%;
  height: 30px;
}
.play_table_header_lbl {
  width: 240px;
  color: #b4b4b4;
  font-size: 10px;
  text-transform: uppercase;
  align-items: center;
  background-color: #3d3d3d;
  line-height: 3;
  margin-left: 6px;
}
.play_table_time_left {
  width: 50px;
  font-size: 10px;
  line-height: 16px;
  background: #3d3d3d;
  color: #b4b4b4;
  height: 28px;
  line-height: 3;
  margin-left: 6px;
}
.play_table_max_nums {
  width: auto;
  color: #b4b4b4;
  font-size: 10px;
  line-height: 3;
  text-align: right;
}
.play_table_up_arrow {
  width: 66%;
  color: #f47f32;
  font-size: 12px;
  line-height: 2.5;
  text-align: center;
}
.playtable_specials_root {
  width: 240px;
  border-right: 1px solid black;
  margin-left: 6px;
}
.playtable_num_generator_root {
  width: 240px;
  border-right: 1px solid black;
}
.playtable_num_gen_buttons {
  background-color: #282828;
  padding: 12px;
  box-sizing: inherit;
}
.playtable_num_gen_btns_header {
  color: #b4b4b4;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 12px;
  text-align: center;
}
.playtable_num_gen_btns_wheel {
  align-items: center;
  display: flex;
  width: 112px;
  margin-left: 25%;
  margin-bottom: 12px;
}
.playtable_num_gen_minus {
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #282828;
  border: 1px solid black;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}
.playtable_num_gen_value {
  color: #b4b4b4;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  width: 48px;
}
.playtable_num_gen_plus {
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #282828;
  border: 1px solid black;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}
.playtable_num_gen_action {
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.1s ease-in;
  color: black;
  font-weight: 600;
  background-color: #f47f32;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 6px;
}

.playtable_com_header {
  display: flex;
  height: 28px;
  color: #b4b4b4;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 0 12px;
  align-items: center;
  background-color: #282828;
  margin-bottom: 8px;
}
.playtable_com_content {
  text-align: left;
  padding: 8px 12px;
  box-sizing: inherit;
}
.playtable_comb_radiobtn {
  margin-bottom: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.playtable_comb_radiobtn input {
  margin-right: 9px;
}
.playtable_comb_radiobtn label {
  height: 24px;
  color: #b4b4b4;
  font-size: 12px;
  line-height: 24px;
}
.playtable_favs_root_header {
  height: 28px;
  background-color: #282828;
}
.playtable_favs_root_header span {
  display: flex;
  color: #b4b4b4;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 3;
  padding: 0 12px;
  align-items: center;
  background-color: #282828;
}
.playtable_favs_label {
  line-height: 20px;
  color: #b4b4b4;
  font-size: 12px;
  text-align: center;
  margin-top: 0;
}
.playtable_favs_button {
  transition: all 0.1s ease-in;
  outline: 0;
  margin: 0;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin-top: 12px;
  text-transform: uppercase;
  background: #282828;
  border: 1px solid black;
  color: #b4b4b4;
  font-weight: 600;
  width: calc(100% - 24px);
  margin-left: 15px;
}
.playtable_favs_button:hover {
  background: #b4b4b4;
  color: #282828;
}
.playtable_playtable_root {
  display: flex;
  flex-wrap: wrap;
}
.playtable_pt_element {
  width: 39px;
  margin-left: 6px;
  margin-right: 6px;
}
.playtable_pt_statistics {
  font-size: 10px;
  display: flex;
}
.playtable_stats_hot {
  color: #529900;
  margin-right: 6px;
}
.playtable_stats_cold {
  color: #ea060a;
}
.playtable_pt_value {
  display: block;
  width: 27px;
  height: 27px;
  line-height: 1.7;
  border-radius: 16px;
  text-align: center;
  border: 1px solid black;
  background-color: darkgray;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 6px;
  transition: all 0.1s ease-in;
  user-select: none;
}
.playtable_pt_value label {
  cursor: pointer;
}
.playtable_pt_value:hover {
  background-color: #f47f32;
  border: 1px solid black;
  color: black;
  cursor: pointer;
}
.playtable_value_active {
  background-color: #f47f32 !important;
  border: 1px solid black !important;
  color: black;
  border-radius: 16px;
}
/*----TICKET------VERIFICATION-------*/
.ticket_verify_root {
  width: 330px;
  border-radius: 4px;
  background-color: #282828;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 6px 8px;
  margin-bottom: 8px;
  z-index: 105;
  display: flex;
  color: #f47f32;
}
.ticket_verify_root {
  justify-content: flex-start;
  margin-right: 15%;
}
.ticket_verify_label {
  font-size: 12px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 0;
  font-size: 90%;
  font-weight: 400;
  line-height: 26px;
}
.ticket_verify_right {
  display: flex;
  justify-content: flex-end;
  margin-left: 12%;
}
.ticket_verify_intup {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  font-size: 12px;
  outline: 0;
  padding: 0 6px;
  color: black;
  height: 24px;
  line-height: 24px;
  background-color: darkgray;
}
.ticket_verify_button {
  cursor: pointer;
  outline: 0;
  color: black;
  background-color: #f47f32;
  height: 26px;
  width: 26px;
  align-items: center;
  border: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 2px;
}

/*-----Ticket Header----*/
.ticket_header_text_text {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f47f32;
  height: 36px;
  align-items: center;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}
.ticket_header_text {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: black;
  text-transform: uppercase;
  line-height: 2.4;
}
.ticket_errors_and_stuff {
  background-color: rgba(0, 0, 0, 0.65);
  color: #f47f32;
  text-align: center;
  justify-content: space-between;
}
.ticket_subheader {
  background-color: #282828;
  padding-left: 12px;
  color: #777;
  font-size: 12px;
  border-bottom: 1px solid darkgray;
  min-height: 28px;
  line-height: 16px;
  display: flex;
}
.ticket_subheader_infos {
  margin-right: 12px;
  display: flex;
}
.ticket_subheader_infos_text {
  margin-right: 3px;
  margin-top: 7px;
}
.ticket_subheader_infos_value {
  font-weight: 700;
  margin-top: 7px;
}
.ticket_subheader_actions {
  margin-left: 55%;
}
.ticket_subheader_actions:hover {
  background-color: rgb(49, 49, 49);
}
.ticket_subheader_clear {
  color: #777;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  background-color: transparent;
  margin: 0;
  border: 0;
  outline: 0;
  margin-right: 6px;
  margin-top: 3px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 2px;
  line-height: 16px;
}
.ticket_subheader_icontrash {
  text-align: center;
  font-size: 12px;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
.ticket_numbers_root {
  margin-top: 1%;
}
.ticket_time {
  color: #999;
  font-size: 10px;
  font-weight: 500;
  margin-right: 12px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 3%;
}
.ticket_numbers_header {
  margin-top: 1%;
  display: flex;
}
.ticket_numbers_header_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  width: 200px;
  text-align: left;
  margin-right: 16px;
  margin-left: 2.5%;
  margin-top: 1%;
  color: #999;
}
.ticket_numbers_header_ballSystem {
  display: flex;
  margin-left: 27%;
}
.ticket_numbers_guess {
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  z-index: 1;
}
.ticket_numbers_outof {
  background-color: #7a7a7a;
  border: 1px solid transparent;
  color: black;
}
.ticket_numbers_guess,
.ticket_numbers_outof {
  border-radius: 50%;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.ticket_numbers_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px;
  padding-right: 0;
  margin-left: 1%;
}
.ticket_ticket_ball {
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 35px;
  height: 35px;
  margin-right: 3px;
  margin-left: 6px;
  margin-bottom: 9px;
  position: relative;
  color: black;
}
.ticket_ticket_ball span {
  display: block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid darkgray;
  background-color: white;
  font-size: 15px;
  font-weight: 500;
  margin-top: 2px;
  margin-right: 2px;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.ticket_ticket_ball span:hover {
  background-color: #f47f32;
  color: white;
}
.ticket_combinations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ticket_combinations_single_elem {
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  margin-bottom: 8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  height: 30px;
}
input[type="checkbox"] {
  zoom: 1.4;
  border: 1px solid #f47f32;
  background-color: #f47f32;
}
.ticket_combinations_single_elem label {
  font-size: 80%;
  color: darkgray;
  font-weight: 700;
  margin-left: 6%;
  line-height: 25px;
}
.ticket_stake_root {
  border-top: 1px solid darkgray;
  background-color: #282828;
  border-bottom: 1px dashed gray;
  padding: 12px 12px 8px 12px;
}
.ticket_stake_comb_number {
  display: flex;
}
.ticket_stake_comb_label {
  color: #f47f32;
  font-size: 12px;
  font-weight: 650;
  margin-bottom: 8px;
}
.ticket_stake_comb_value {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  color: #f47f32;
  font-size: 12px;
  font-weight: 700;
  margin-right: 2%;
}
.ticket_stake_stake {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticket_stake_stake_buttons {
  align-items: center;
  display: flex;
}
.ticket_stake_your_bet {
  margin-right: 8px;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  font-weight: 500;
  color: darkgray;
}
.ticket_stake_buttons_selectors {
  border: 1px solid black;
  background-color: #282828;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  color: darkgray;
  width: 120px;
  display: flex;
  height: 24px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.ticket_stake_picker {
  border-right: 1px solid black;
  flex: 1;
  justify-content: center;
  text-align: center;
  line-height: 23px;
  border-bottom: none;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  color: #999;
  font-weight: 500;
  font-size: 14px;
}
.ticket_stake_stake_stakes {
  display: flex;
}
.ticket_stake_stakes_label {
  color: darkgray;
  font-weight: 400;
  line-height: 2.5;
  margin-right: 4px;
  margin-left: 8px;
  font-size: 10px;
}
.ticket_stake_stake_input {
  border-radius: 3px;
  color: black;
  margin-bottom: 4px;
  height: 15px;
  padding: 0 8px;
  outline: 0;
  width: 72px;
  line-height: normal;
  background-color: darkgray;
  text-align: right;
  outline: 0;
  border: 1px solid #e3e3e3;
  font-weight: 650;
  font-size: 12px;
  padding: 4px;
  align-items: center;
}
.ticket_stake_tax {
  display: flex;
  justify-content: space-between;
}
.ticket_stake_tax_amount {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  color: darkgray;
  margin-top: 8px;
  width: 50%;
}
.ticket_stake_tax_final {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  color: darkgray;
  margin-top: 8px;
  text-align: right;
}
.ticket_details_root {
  padding: 12px 12px 0 12px;
  background-color: #282828;
}
.ticket_details_maxpot {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
}
.ticket_details_maxpot_label {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  color: darkgray;
}
.ticket_details_maxpot_value {
  display: flex;
}
.ticket_details_maxpot_value_value {
  color: darkgray;
  font-weight: 500;
}
.ticket_details_maxpot_currency {
  color: darkgray;
  font-size: 10px;
  margin-left: 2px;
  font-weight: 400;
}

.ticket_details_minpot {
  margin-top: 8px;
  font-size: 14px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
}
.ticket_details_minpot_label {
  font-weight: 400;
  justify-content: space-between;
  color: darkgray;
}
.ticket_details_minpot_value_value {
  font-weight: 500;
  color: darkgray;
}
.ticket_details_minpot_value_currency {
  color: #777;
  font-size: 10px;
  margin-left: 2px;
  font-weight: 400;
}

.ticket_buttons_place_bet {
  display: flex;
  margin-top: 26px;
  margin-bottom: 18px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.ticket_buttons_place_bet_button {
  cursor: pointer;
  background-color: #f47f32;
  border: 0;
  border-radius: 4px;
  color: black;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
}
.stake_active {
  background-color: #f47f32;
  color: white;
}

.side_mobile_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 78.1vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  margin-top: 14.8vh;
}
.side_mobile_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  position: relative;
}
.side_mobile_dateselector {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  margin-top: 13vh;
}
.side_mobile_date_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  float: right;
}
.ticket_mobile_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
}
.ticket_mobile_wrap {
  width: 340px;
  max-width: 100%;
  border-radius: 4px;
  margin-left: 7%;
}
.playtable_header_mobile_random {
  background-color: #363636;
  padding: 9px;
  margin-right: 3px;
  border-radius: 3px;
}
.playtable_header_mobile_random:last-of-type {
  margin-right: 0;
}
.playtable_header_mobile_icon {
  width: 36px;
  height: 36px;
  font-size: 36px;
}
.favs_favs_wrap {
  width: 100%;
  display: flex;
  border-bottom: 1px dashed whitesmoke;
}
.playtable_favs_sections {
  height: 90px;
  max-height: 231px;
  overflow-y: auto;
}
.favorites_wrap {
  color: whitesmoke;
  display: flex;
  padding: 9px;

  flex-wrap: wrap;
  width: 90%;
}
.favorite_balls {
  width: 27px;
  height: 27px;
  background-color: darkgray;
  color: black;
  text-align: center;
  line-height: 2;
  border-radius: 50%;
  margin: 3px;
}
.favoriotes_trash {
  width: 10%;
  color: #f47f32;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 24px;
  font-size: 18px;
}
.favorites_wrap:hover {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 12px;
}
.favoriotes_trash:hover {
  color: #ea060a;
}
