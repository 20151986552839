.hour_selector_root {
  display: flex;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
  z-index: 50;
}
.hour_selector_button {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
}
.hour_selector_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.hour_selector_button:hover {
  background-color: #666262;
}
.hour_selector_is_active a {
  all: unset;
}
