.layout_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6vh 93vh;
  grid-template-areas: "navigation" "children";
  row-gap: 0.5rem;
}
.layout_navheader {
  width: 100%;
  height: 6vh;
  grid-area: navigation;
  background-color: #000000;
  color: #fffdff;
  border-bottom: 1px solid #f47f32;
  position: fixed;
  z-index: 119321;
}
.layout_children {
  grid-area: children;
  user-select: none;
}
.err_msg_btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.err_msg_btns > span:nth-child(1) {
  padding: 0.6rem;
  min-width: 39px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  cursor: pointer;
}
.err_msg_btns > span:nth-child(2) {
  min-width: 39px;
  padding: 0.6rem;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  cursor: pointer;
}
@media all and (max-width: 1440px) {
  .layout_root {
    row-gap: 0rem;
  }
}
@media screen and (max-width: 1366px) {
  .layout_root {
    grid-template-rows: 8vh 91vh;
  }
}

.blanker {
  width: 100%;
  height: 13.45vh;
  background-color: #1a1a1a;
  position: fixed;
  z-index: 0;
}
