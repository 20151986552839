.loto_root {
  width: 100%;
  height: 93vh;
  display: grid;
  grid-template-columns: 240px auto 360px;
  grid-template-rows: 36px auto;
  grid-template-areas:
    "side dateSelector ticket"
    "side content ticket";
  column-gap: 1rem;
}
.side_menu_mobile {
  grid-area: sideMobile;
  display: none;
}
.side_menu {
  grid-area: side;
}
.date_selector {
  grid-area: dateSelector;
  position: fixed;
  width: calc(100% - 650px);
  height: 36px;
  margin-left: 265px;
  margin-right: 360px;
}
.hour_selector {
  grid-area: hourSelector;
}
.content {
  grid-area: content;
}
.ticket {
  grid-area: ticket;
}
.ticket_mobile {
  grid-area: ticketMobile;
  display: none;
}

@media screen and (max-width: 1366px) {
  .loto_root {
    height: 91vh;
    grid-template-columns: 220px auto 330px;
    grid-template-rows: 36px auto;
    column-gap: 1rem;
  }
  .date_selector {
    width: calc(100% - 588px) !important;
    margin-left: 238px;
  }
}

@media all and (max-width: 1280px) {
  .loto_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 42px auto 50px;
    grid-template-areas: "sideMobile" "content" "ticketMobile";
  }
  .side_menu {
    display: none;
  }
  .ticket {
    display: none;
  }
  .date_selector {
    display: none;
  }
  .hour_selector {
    display: none;
  }
  .ticket_mobile {
    display: block;
  }
  .side_menu_mobile {
    display: block;
  }
}
@media all and (max-width: 786px) {
  .loto_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "sideMobile" "content" "ticketMobile";
  }
  .side_menu {
    display: none;
  }
  .ticket {
    display: none;
  }
  .date_selector {
    display: none;
  }
  .hour_selector {
    display: none;
  }
  .ticket_mobile {
    display: block;
  }
  .side_menu_mobile {
    display: block;
  }
}

/*Side-menu grid*/
.side_menu_root {
  display: grid;
  grid-template-columns: 240px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "topidx"
    "defaultMenu";
  margin-left: 0.6rem;
  row-gap: 1rem;
  position: fixed;
}
.top_idx_lotteries {
  grid-area: topidx;
}
.tabs_menus {
  grid-area: defaultMenu;
}

@media screen and (max-width: 1366px) {
  .side_menu_root {
    grid-template-columns: 220px;
  }
}

/*Side-menu @-cu-@ generic-side-menu*/
.generic_sidemenu_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto;
  grid-template-areas: "tabs" "genericMenu";
  row-gap: 0.3rem;
}
.generic_tabs {
  grid-area: tabs;
}
.generic_list {
  grid-area: genericMenu;
}

/*Content Area*/
.content_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto;
  grid-template-areas: "miniHeader" "contentList";
  row-gap: 0.1rem;
}
.content_mini_header {
  grid-area: miniHeader;
}
.content_list {
  grid-area: contentList;
}

@media screen and (max-width: 1366px) {
  .content_mini_header {
    width: calc(100% - 591px) !important;
    margin: 0 !important;
    margin-left: 4px !important;
    margin-top: 4px !important;
  }
}

@media all and (max-width: 1440px) {
  .content_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px 80vh;
    grid-template-areas: "miniHeader" "contentList";
    row-gap: 0;
  }

  .content_miniheader_odds {
    display: none;
  }
  .content_miniheader_odd {
    display: none;
  }
}

/*CONTENT SINGLE ROW*/
.content_single_row_root {
  display: grid;
  grid-template-columns: 7% 3% 16% auto 40.5%;
  grid-template-rows: auto minmax(0px, auto);
  grid-template-areas:
    "data shopCode nume system cote"
    "playtable playtable playtable playtable playtable";
}
.content_single_row_date {
  grid-area: data;
}
.content_single_row_shopCode {
  grid-area: shopCode;
}
.content_single_row_name {
  grid-area: nume;
}
.content_single_row_system {
  grid-area: system;
}
.content_single_row_odds {
  grid-area: cote;
}
.playtable {
  grid-area: playtable;
  min-height: 540px;
}
@media all and (max-width: 1440px) {
  .content_single_row_root {
    display: grid;
    grid-template-columns: 150px 80px auto 150px;
    grid-template-rows: auto minmax(0px, auto);
    grid-template-areas: "data shopCode nume system" "playtable playtable playtable playtable";
  }
  .content_single_row_odds {
    display: none !important;
  }
}
@media all and (max-width: 786px) {
  .content_single_row_root {
    display: grid;
    grid-template-columns: 90px 30px auto 50px;
    grid-template-rows: auto minmax(0px, auto);
    grid-template-areas: "data shopCode nume system" "playtable playtable playtable playtable";
  }
  .content_single_row_odds {
    display: none !important;
  }
}

/*------PLAY TABLE-------*/
.playtable_root {
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-rows: 30px auto;
  grid-template-areas:
    "pt_header pt_header"
    "left_pt right_pt";
}
.playtable_header {
  grid-area: pt_header;
}
.playtable_left_specials {
  grid-area: left_pt;
}
.playtable_header_mobile {
  grid-area: pt_header_specials;
}
.playtable_right_game {
  margin-left: 6px;
  padding: 16px;
  grid-area: right_pt;
}
.playtable_header_mobile {
  display: none;
}
.playtable_mobile_special_buttons_res {
  display: none;
  grid-area: pt_header_special_div;
}

.playtable_specials_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 130px 240px auto;
  grid-template-areas:
    "generator"
    "combinations"
    "addFav";
}
.playtable_specials_num_gen {
  grid-area: generator;
}
.playtable_specials_combinations {
  grid-area: combinations;
}
.playtable_specials_favs {
  grid-area: addFav;
}

@media all and (max-width: 1280px) {
  .playtable_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20px 80px auto auto;
    grid-template-areas: "pt_header" "pt_header_specials" "pt_header_special_div" "right_pt";
  }
  .playtable_left_specials {
    display: none;
  }
  .play_table_header_lbl {
    display: none;
  }
  .play_table_time_left {
    display: none;
  }
  .play_table_max_nums {
    display: none;
  }
  .play_table_up_arrow {
    width: 100% !important;
  }
  .play_table_up_arrow {
    width: auto;
    text-align: left;
  }
  .playtable_header_mobile {
    display: flex !important;
    margin-top: 12px;
  }
  .playtable_header_mobile_random {
    width: 33%;
    line-height: 5;
    text-align: center;
    color: white;
    cursor: pointer;
  }
  .playtable_mobile_special_buttons_res {
    display: block;
  }

  .playtable_num_generator_root {
    width: 100% !important;
    border: none !important;
  }
  .playtable_num_gen_btns_header {
    margin: 0 !important;
    text-align: center;
  }
  .playtable_num_gen_btns_wheel {
    width: 45% !important;
    margin-left: 35% !important;
    margin-right: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .playtable_com_header {
    margin-left: 10px !important;
    margin-bottom: 0 !important;
  }
}

/*--------TICKET---------*/
.ticket_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto auto auto auto auto;
  grid-template-areas:
    "verification"
    "ticketHead"
    "ticketNumbers"
    "ticketCombinations"
    "ticketStake"
    "ticketButton";
  margin-right: 2%;
  width: 350px;
  height: auto;
  position: fixed;
}
.ticket_verify_ticket {
  grid-area: verification;
}
.ticket_header {
  margin-top: 21px;
  grid-area: ticketHead;
  background-color: #282828;
}
.ticket_nums {
  grid-area: ticketNumbers;
  background-color: #282828;
}
.ticket_combinations {
  grid-area: ticketCombinations;
  background-color: #282828;
}
.ticket_stake {
  grid-area: ticketStake;
  background-color: #282828;
}
.ticket_button {
  grid-area: ticketButton;
  background-color: #282828;
}

@media screen and (max-width: 1366px) {
  .ticket_root {
    width: 320px;
    margin: 0 !important;
  }
  .ticket_verify_root {
    width: 320px !important;
    padding: 3px !important;
  }
  .ticket_verify_intup {
    padding: 1px !important;
    height: 20px !important;
  }
  .ticket_verify_button {
    height: 23px !important;
    width: 23px !important;
  }
}

@media all and (max-width: 786px) {
  .top_idx_lotteries {
    max-height: 30vh;
    overflow: auto;
  }
  .generic_list {
    max-height: 45vh !important;
    overflow: auto;
  }
}
