/*-----STUFF SELECTOR--------*/
.stuff_selector_root {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  background-color: #282828;
}
.results_stuff_selector_item {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 3px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
}
.results_stuff_selector_item:hover {
  background-color: #666262;
}
.stuff_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}

/*----SIDE MENU----*/
.side_menu_top_idx {
  background-color: #282828;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid black;
}

/*---CONTENT AREA ----*/
.content_results_miniheader {
  width: calc(100% - 630px);
  display: flex;
  align-items: center;
  background-color: #282828;
  font-size: 10px;
  color: #b4b4b4;
  position: sticky;
  z-index: 40;
  margin-top: 6px;
  height: 30px;
  position: fixed;
}
@media all and (max-width: 1280px) {
  .content_results_miniheader {
    width: 100%;
    position: unset;
  }
}
.content_results_minih_date {
  width: 10%;
  margin-left: 9px;
}
.content_results_minih_label {
  width: 20%;
}
.content_results_minih_nums {
  width: 69%;
}
/* .content_results_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.content_results_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.content_results_list::-webkit-scrollbar-thumb {
  background-color: #f47f32;
} */

/*----single row results---*/
.content_results_single_row_root {
  display: flex;
  color: #333;
  font-size: 12px;
  border-bottom: 1px solid black;
  background-color: #282828;
  height: 30px;
}
.content_results_single_row_date {
  width: 10%;
  margin-left: 9px;
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  line-height: 3;
}
.content_results_single_row_label {
  width: 20%;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.5;
  text-align: left;
  color: whitesmoke;
}
.results_single_row_name {
  width: 50%;
}
.results_single_row_system {
  display: flex;
}
.results_single_row_guess {
  margin-top: 6px;
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
}
.results_single_row_outof {
  margin-top: 6px;
  background-color: #7a7a7a;
  color: black;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
  border: 1px solid transparent;
}
.content_results_single_row_numbers {
  display: flex;
  flex-wrap: wrap;
}
.content_results_single_row_num {
  margin-top: 3px;
  display: inline-block;
  background-color: rgba(244, 127, 50, 0.8);
  height: 24px;
  width: 24px;
  margin-right: 6px;
  text-align: center;
  line-height: 2;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  color: black;
}

@media all and (max-width: 1540px) {
  .content_results_single_row_root {
    display: block;
    color: #333;
    height: 120px;
  }
  .content_results_single_row_date {
    width: 100%;
    height: 30px;
    margin-left: 9px;
  }
  .content_results_single_row_label {
    width: 100%;
    height: 30px;
    margin-left: 9px;
  }
  .results_single_row_name {
    width: auto;
    margin-right: 9px;
  }
  .content_results_single_row_numbers {
    height: 30px;
    width: 100%;
    margin-left: 9px;
  }
}

/*---CALENDAR---*/
.calendar_root {
  display: block;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  z-index: 50;
  position: fixed;
  width: 350px;
}
@media all and (max-width: 1440px) {
  .calendar_root {
    position: unset;
    width: 100%;
  }
}
.calendar_selector_button {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
  height: 20px;
  margin-top: 1px;
}
.calendar_selector_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.calendar_selector_button:hover {
  background-color: #666262;
}
.calendar_selector_button a {
  all: unset;
}
