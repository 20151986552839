.nav_more {
  cursor: pointer;
  align-items: center;
  color: #fffdff;
  padding: 15px 0px 12px 6px;
}
.nav_more_icon {
  text-align: center;
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.nav_logo {
  margin-left: 3px;
  font-weight: 500;
  cursor: pointer;
}
.nav_logo img {
  height: 60px;
}
.nav_menu_item {
  color: #fffdff;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 12px 0px 12px;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
}
.is_active::after {
  content: "";
  position: absolute;
  left: 2%;
  right: 2%;
  bottom: 0;
  border: 2px solid #f47f32;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.nav_menu_item a {
  all: unset;
}
.is_active {
  color: #f47f32;
}
.nav_date {
  padding: 9px 3px 0px 0px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}
.nav_time {
  padding: 0px 3px 6px 0px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}
.nav_login {
  width: 80%;
  padding: 1 6 1 6;
  margin-left: 10%;
  line-height: 3;
  text-align: center;
  height: 3vh;
  margin-top: 1.2vh;
  background-color: #f47f32;
  border-radius: 3px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #f47f32;
  font-weight: 500;
}
.nav_login:hover {
  background-color: #ca6e31;
  border: 1px solid #9b4b16;
}
.nav_register a {
  all: unset;
}
.nav_register {
  width: 80%;
  padding: 1 6 1 6;
  line-height: 3;
  text-align: center;
  height: 3vh;
  margin-top: 1.2vh;
  border-radius: 3px;
  border: 1px solid white;
  color: white;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}
.nav_register:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/*---MODAL LOGIN---*/

.modal_login {
  position: fixed;
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  overflow: hidden;
}
.modal_login_container {
  width: 360px;
  max-width: 360px;
  background-color: rgb(187, 187, 187);
  border-radius: 4px;
  padding: 32px 40px;
  margin: 120px auto 60px;
  position: relative;
}
.modal_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background-color: rgb(187, 187, 187);
}
.modal_title {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.modal_header_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #999;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-left: 12px;
}
.modal-forms {
  padding: 8px 0;
}
.form-group {
  box-sizing: inherit;
}
.form-field-user {
  margin-bottom: 12px;
  min-height: 76px;
  position: relative;
}
.input-label {
  display: flex;
  font-size: 12px;
  font-weight: 550;
  line-height: 16px;
  color: #333;
  margin-bottom: 8px;
}
.input-field-box {
  box-sizing: inherit;
}
.input-field {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 100%;
  color: #333;
  font-size: 12px;
  margin-bottom: 4px;
  height: 32px;
  padding: 0 8px;
  outline: 0;
}
.pass-input {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.forgot-pass {
  color: #333;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  margin-left: 67%;
}
.modal-login-button {
  margin-top: 20px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 105%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #f47f32;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  padding: 0 12px;
  height: 36px;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.modal_buttons {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 8px;
  color: #333;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
}
.goto_link {
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: underline !important;
}

.user_badge_wrap {
  all: unset;
  display: block;
  text-align: center;
  padding: 6px;
  margin-top: 3px;
  cursor: pointer;
}
.user_badge_user {
  color: #4a4a4a;
  font-size: 12px;
}
.user_badge_balance {
  color: #f47f32;
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
}
.more_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 21vh; */
  margin-top: 6vh;
  background-color: rgba(247, 117, 30, 0.99);
  overflow: hidden;
  position: fixed;
  border-radius: 6px;
}
.more_mobile_wrapper {
  border-radius: 4px;
  display: block;
  padding: 12px;
}
.more_mobile_wrapper a {
  all: unset;
  color: black;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 12px 0px 12px;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 12px;
}
.is_mob_active {
  background-color: #f3731e;
}
.bottom_brd {
  border-bottom: 1px solid black;
  width: 100%;
}
.login_failed_err_msg {
  text-align: center;
  color: red;
  font-size: 12px;
  margin-bottom: 6px;
  font-weight: 500;
}
@media all and (max-width: 786px) {
  .modal_login_container {
    width: 270px;
    max-width: 270px;
  }
}
